<style>
.notCoveredDisable {
  pointer-events: none;
}
body.default-style #illness-packages>.container #section-container section{
  margin:0 0 1rem 0 !important;
}

body.default-style #illness-packages #package-summary .item-list .item{
  color: #003b71 !important;
}

body.default-style #illness-packages #package-summary .item-list .item.incative{
  color: #5d6d7e !important;
}

body.default-style #illness-packages #package-summary .item-list .item.incative.nonselected{
  color: #C0C0C0 !important;
}
body.default-style .modal-v1 .modal-dialog {
  max-width: 540px !important;
}
@media (max-width: 501px){
  body.default-style .modal-v1 .modal-dialog .modal-content .modal-footer.justify-content-center .btn-secondary{
    height: 88px !important;
  }
}
#illness-packages .fixed-5year-premium{
  padding: 8px 16px; 
  border-radius: 15px; 
  /* background-color: #083B6F; */
  color: #083B6F; 
  /* color: #f5f5f5; */
  border: 2px solid #083B6F;
  font-size: 12px;
}

#illness-packages .header.fixed-5year-premium{
  padding: 6px 14px; 
  border-radius: 12px; 
  /* background-color: #083B6F; */
  color: #ffffff; 
  /* color: #f5f5f5; */
  border: 2px solid #ffffff;
  font-size: 12px;
}

#illness-packages #fixed-header{
  background: #003b71;
  background-image: linear-gradient(90deg,#015caf 22%,#003b71 78%);
  background-image: -webkit-linear-gradient(90deg,#015caf 22%,#003b71 78%);
  display:none;
  color:#fff;
  padding:10px;
  width:100%;
}

body.default-style #illness-packages>.container .alike-card .item{
  padding: 0 20px 0 20px;
}

body.default-style #illness-packages>.container .alike-card .custom-scroll>.item-wrapper>.item{
  background: #e3e3e3;
  border: 1px solid #e3e3e3;
}

body.default-style #illness-packages>.container .alike-card .custom-scroll>.item-wrapper>.item.active{
  background: #015caf;
  border-color: #015caf;
  box-shadow: 0 4px 5px 2px rgb(1 92 175 / 35%) !important;
  /* background-image: linear-gradient(0deg,#003b71 0,#015caf 100%); */
}

body.default-style #illness-packages>.container .alike-card .custom-scroll>.item-wrapper>.item.active .desc-dengue-1{
  color: #fff;
  font-size:13px; 
  padding:0; 
  display: block; 
  text-align: right;
}

body.default-style #illness-packages>.container .alike-card .custom-scroll>.item-wrapper>.item.active .desc-dengue-2{
  color: #fff;
  font-size:10px; 
  padding:0; 
  display: block; 
  text-align: right;
}

body.default-style #illness-packages>.container .alike-card .custom-scroll>.item-wrapper>.item .desc-dengue-1{
  font-size:13px; 
  padding:0; 
  display: block; 
  text-align: right;
}
body.default-style #illness-packages>.container .alike-card .custom-scroll>.item-wrapper>.item .desc-dengue-2{
  font-size:10px; 
  padding:0; 
  display: block; 
  text-align: right;
}

body.default-style #illness-packages>.container .alike-card .custom-scroll>.item-wrapper>.item.active .desc{
  color: #fff;
}

body.default-style #illness-packages>.container .alike-card .custom-scroll>.item-wrapper>.item.active .desc{
  color: #fff;
}

body.default-style #illness-packages>.container .alike-card .custom-scroll>.item-wrapper>.item.active .price{
  color: #fff;
}

body.default-style #illness-packages>.container .alike-card .custom-scroll>.item-wrapper>.item.active .cover-value{
  color: #fff;
}
.btn-left{
  z-index: 5;
  position:absolute;
  transform: translateY(-60%);
  left: -15px;
  top: 60%;
  border-radius: 50%;
  background-color: #FFF;
  border: 1px solid #f5f5f5;
  box-shadow: #e3e3e3 0px 1px 5px 0px;
  -webkit-box-shadow: #e3e3e3 0px 1px 1px 0px  !important;
}
.btn-right{
  z-index: 5;
  position:absolute;
  transform: translateY(-60%);
  right: -10px;
  top: 60%;
  border-radius: 50%;
  background-color: #FFF;
  border: 1px solid #f5f5f5;
  box-shadow: #e3e3e3 0px 1px 5px 0px;
  -webkit-box-shadow: #e3e3e3 0px 1px 1px 0px  !important;
}

#illness-packages .btn-confirm{
  background-color: #ff5011;
  border-radius: 5px;
  border: 1px solid #ff5011 !important;
  color: #fff;
  padding: 20px;
}
#illness-packages .btn-confirm.disabled, .btn-confirm:disabled{
  border: 1px solid #ccc !important;
  color: #ccc !important;
  background-color: #FFF !important;
  -webkit-box-shadow: inset 1px 1px 6px 1px rgb(0 0 0 / 15%) !important;
  box-shadow: 1px 1px 6px 1px rgb(0 0 0 / 15%) !important;
}
#illness-packages .btn-cancel{
  color: #ff5011 !important;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #ff5011 !important;
  border-color: #ff5011 !important;
  padding: 20px;
}
body.scroll-down #illness-packages #fixed-header{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  box-shadow: silver 0 1px 5px 0;
  border-radius: 0 0 8px 8px;
  -webkit-border-radius: 0 0 8px 8px;
  display: block;
}

@media (max-width: 350px){
  #illness-packages #fixed-header{
    padding:10px 0px;
  }
}

#illness-packages #fixed-header .header-container{
  position: relative;
  text-align: center;
}

#illness-packages #fixed-header .header-container .btn-absolute{
  color: #fff;
  background: transparent;
  border: 0;
  position: absolute;
  top: 5px;
}

#illness-packages #fixed-header .header-container .btn-absolute.left{
  left:0px;
}

#illness-packages #fixed-header .header-container .btn-absolute.right{
  right:0px;
}

#illness-packages #fixed-header .header-container .package-premium,
#illness-packages .package-premium{
  font-size: 2.8rem;
  line-height: 1;
}

.frame-summary{
  border: 2px solid #003b71;
  border-radius: 5px;
}

.img-watermark{
  position: absolute;
  right: -5%;
  top: 30;
}
@media (max-width: 1150px){
  .img-watermark{
    right: -5%;
    top: 30;
  }
}

@media (max-width: 1024px){
  .img-watermark{
    right: -5%;
    top: 30;
  }
}@media (max-width: 960px){
  .img-watermark{
    right: -5%;
    top: 30;
  }
}
@media (max-width: 760px){
  .img-watermark{
    right: 0;
    top: 30;
    width: 120px;
  }
}
  @media (max-width: 540px){
    .img-watermark{
      right: 0;
      top: 30;
      width: 100px;
    }
  }
  @media (max-width: 430px){
    .img-watermark{
      right: 0;
      top: 30;
      width: 100px;
    }
  }
  @media (max-width: 360px){
    .img-watermark{
      right: -10;
      top: 30;
      width: 0;
    }
  }

#illness-packages #fixed-header .header-container .package-premium small,
#illness-packages .package-premium small{
  font-size:45%;
}

@media (max-width: 600px){
 #illness-packages #fixed-header .header-container .package-premium,
 #illness-packages .package-premium{
    font-size: 1.8rem;
  }
}

@media (max-width: 350px){
 #illness-packages #fixed-header .header-container .package-premium,
 #illness-packages .package-premium{
    font-size: 1.5rem;
  }
}

#illness-packages #fixed-header .header-container .installment-desc{
  color: #ddd;
  margin-bottom:10px;
}

#illness-packages #fixed-header .header-container .roojaiRewards{
  color: #fff;
}

#illness-packages .light-blue{
  color: #4991d2;
}

#illness-packages .question-mark-red, #illness-packages .question-mark-red:active, #illness-packages .question-mark-red:focus {
    padding: 0;
    margin: 0;
    width: 20px;
    min-width: 20px;
    line-height: 20px;
    background-color: #fff!important;
    color: #ff5011!important;
    display: inline-block;
    border-radius: 50%;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    border: 1pt solid #ff5011!important;
    cursor: pointer;
    text-align: center;
    font-size: 14px;
    text-decoration: none;
}

#illness-packages .packagePremium{
  font-size: 2.8rem;
  line-height: 1;
}

#illness-packages .packagePremium small{
  font-size:45%;
}
@media (max-width: 600px){
 #illness-packages .packagePremium{
    font-size: 1.8rem;
  }
}

@media (max-width: 350px){
 #illness-packages .packagePremium{
    font-size: 1.5rem;
  }
}

#illness-packages .paymentOptions.other-package{
  /* text-align: center; */
  padding: 0px 25px;
  max-width:400px;
}
body.default-style #illness-packages > .container .alike-card.other-package{
  padding: 10px 15px;
}

#illness-packages .paymentOptions .switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
  margin:0 10px -5px 10px;
}

#illness-packages .paymentOptions .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #FFAC50;
  -webkit-transition: .4s;
  transition: .4s;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

#illness-packages .paymentOptions .slider:before {
  position: absolute;
  content: "";
  height: 25px;
  width: 25px;
  left: 0px;
  bottom: 0px;
  background-color: #FF5011;
  -webkit-transition: .4s;
  transition: .4s;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

#illness-packages .paymentOptions .slider.disabled{
  background-color: #f5f5f5!important;
}

#illness-packages .paymentOptions .slider.disabled:before {
  background: #f7fcff!important;
}

#illness-packages .paymentOptions .slider.annual {
  background-color: #FFAC50;
}

#illness-packages .paymentOptions .slider.annual:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
  background:  #FF5011;
}

#illness-packages .paymentOptions .slider.round {
  border-radius: 50px;
}

#illness-packages .paymentOptions .slider.round:before {
  border-radius: 50%;
}

#illness-packages .paymentOptions .option-annual{
  color: #e3e3e3;
  font-size: 1.3rem;
}
#illness-packages .paymentOptions .option-installment{
  color: #e3e3e3;
  font-size: 1.3rem;
}


#illness-packages .paymentOptions .option-annual.active{
  color: #FF5011;
  font-size: 1.3rem;
}

#illness-packages .paymentOptions .option-annual.active.disabled{
  color: #e3e3e3 !important;
  font-size: 1.3rem;
}


#illness-packages .paymentOptions .option.disabled{
  color: #e3e3e3 !important;
}

#illness-packages .paymentOptions .option-installment.active.disabled{
   color: #e3e3e3 !important;
}

#illness-packages .paymentOptions .option-installment.active{
  color:#FF5011;
}

#illness-packages .roojai-suggestion{
  position: relative;
}

#illness-packages .roojai-suggestion .tooltip2extra {
    margin: 0 auto;
    text-align: center;
    font-size: 18px;
    color: #015caf;
}

#illness-packages .roojai-suggestion .tooltip2extra .tooltip2extra {
    width: 100%;
    background-color: #fff;
    border: 1px solid #015caf;
    padding: 15px 10px;
    border-radius: 4px;
    font-weight: 700;
}

#illness-packages .roojai-suggestion .tooltip2extra .arrow2extra {
    position: relative;
    width: 30px;
    height: 15px;
    background-color: #fff;
    margin: -1px auto 0;
}

#illness-packages .roojai-suggestion .tooltip2extra .arrow2extra:after, #illness-packages .roojai-suggestion .tooltip2extra .arrow2extra:before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-left: 16px solid transparent;
    border-right: 16px solid transparent;
    left: 0;
}

#illness-packages .roojai-suggestion .tooltip2extra .arrow2extra:before {
    border-top: 16px solid #fff;
    top: -1px;
    z-index: 5;
}

#illness-packages .roojai-suggestion .tooltip2extra .arrow2extra:after {
    display: block;
    border-top: 16px solid #015caf;
    top: 0;
}

#illness-packages .roojai-suggestion .suggestion-options{
  display: grid;
  grid-template-columns: 45% 45%;
  gap: 10px;
  width: 100%;
  justify-content: center;
  padding: 15px 0;
}

#illness-packages .roojai-suggestion .suggestion-options .btn{
  box-shadow: 0 2px 5px 1px rgb(188 223 253 / 50%);
  background:#fff;
  color: #015caf;
  border-color:rgba(188, 223, 253);
  display: block;
  padding:13px 0;
  font-weight: bold;
  border-radius: 8px;
}


#illness-packages .floating-continue3x {
    position: fixed;
    bottom: 0;
    z-index: 999;
    margin: 0 auto;
    display: block;
    width: 100%;
    height: auto;
}

#illness-packages .floating-continue3x .floating-wrap {
    max-width: 600px;
    margin: 0 auto;
    padding: 15px;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    border-radius: 10px 10px 0px 0px;
    background-color: #196bb6;
    background-image: -webkit-gradient(linear,left top,left bottom,from(#003b71),color-stop(75%,#003b71),to(#003b71));
    background-image: linear-gradient(180deg ,#003b71,#003b71 75%,#003b71);
}

#illness-packages .floating-continue3x .floating-wrap > .btn{
  display: block;
  border-radius: 10px;
  font-size: 14px;
  -webkit-box-shadow: 0 4px 5px 2px rgb(255 80 17 / 50%);
  box-shadow: 0 1px 6px 2px rgb(255 80 17 / 50%);
}

#illness-packages .btn-email{
  /* width: 33%; */
  background-color: #fff;
  color: #ff5011;
  margin-bottom: 10px;
  font-size: 17px;
}

#illness-packages .btn-callMeback{
  /* width: 33%; */
  color: #ff5011;
  background-color: #fff;
  margin-bottom: 10px;
  font-size: 17px;
}

#illness-packages .btn-wa{
  /* width: 33%; */
  color: #ff5011;
  background-color: #fff;
  margin-bottom: 10px;
}
#illness-packages .alike-card.bar-top{
  border-top: 10px solid #015CAF;
}

#illness-packages .btn-buynow{
  /* width: 100%; */
  font-size: 18px;
  color: #fff;
  background-color: #ff5011;
}
body.default-style .buy-now:disabled{
  border: 1px solid #ccc !important;
  color: #ccc !important;
  background-color: #FFF !important;
  -webkit-box-shadow: inset 1px 1px 6px 1px rgb(0 0 0 / 15%) !important;
  box-shadow: 1px 1px 6px 1px rgb(0 0 0 / 15%) !important;
  opacity: 1;
}
.btn-buynow:disabled{
  border: 1px solid #ccc !important;
  color: #ccc !important;
  background-color: #FFF !important;
  -webkit-box-shadow: inset 1px 1px 6px 1px rgb(0 0 0 / 15%) !important;
  box-shadow: 1px 1px 6px 1px rgb(0 0 0 / 15%) !important;
  opacity: 1;
}

#illness-packages .alike-card-item{
  border-radius: 8px;
  padding: 20px 15px;
  width: 100%;
  background-repeat: no-repeat;
  background-position: right -20px;
}

.info-icon {
  width: 30px;
  height: 30px;
}

.info-icon .fill-orange {
  fill: #FF4713;
}
#illness-packages header{
    border: none;
    background-image: linear-gradient(0deg,#003b71 0,#015caf 100%);
    color: #fff;
    padding: 2px 0 0;
    box-shadow: 0 2px 10px 1px rgb(0 0 0 / 10%);
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
    border-radius: 0 0 8px 8px;
    display: none;
}

#illness-packages header.fixed-menu{
    display: block;
}

#illness-packages header nav .nav-container{
  position: relative;
  text-align: center;
  display: flex;
  justify-content: center;
  padding: 15px 0;
}

#illness-packages header nav .nav-container > button.badge{
  position: absolute;
  top: 15px;
  border: 0;
  color:#ddd;
  font-size: 90%;
}

#illness-packages header nav .nav-container > button.badge:hover{
  color:#fff;
}
body.default-style #top-bar .details2drop4v3{
  top: 50%;
  transform: translateY(-50%);
  margin:20px auto 0;
}

#illness-packages header nav .nav-container > button.badge.goback-btn{
  top: 50%;
  margin-top: -10px;
  margin-left: 10px;
  transform: translateY(-50%);
  left: 0;
}

body.default-style .badge{
  color: #fff;
}

#illness-packages header nav .nav-container > button.badge.installment-btn{
  right: 0;
}

#illness-packages header nav .nav-container > .price-div{
  width: 30%;
  display: block;
  margin: 0 auto;
  text-align: center;
  line-height: 1;
}

#illness-packages header nav .nav-container > .price-div > .price{
  font-size: 1.5rem;
}

#illness-packages header nav .nav-container > .price-div > .price > .total-price{
  font-size: 3rem;
}

#illness-packages header nav .nav-container > .price-div > .installment{
  font-size: 1.2rem;
}

body.default-style #illness-packages>.container .alike-card.red-shadow {
    box-shadow: 0 3px 6px 3px rgb(153 153 153 / 100%);
    -webkit-box-shadow: 0 3px 6px 3px rgb(153 153 153 / 100%);
}

body.default-style #illness-packages>.container .alike-card.blue-shadow {
    box-shadow: 0 4px 5px 2px rgb(6 25 70 / 35%);
    -webkit-box-shadow: 0 4px 5px 2px rgb(6 25 70 / 35%);
}

body.default-style #illness-packages>.container .alike-card .custom-scroll{
    padding: 0 0 20px 0;
    overflow-x: auto;
    /* for scrollbar FF */
    scrollbar-color: #003b71 #dddddd;
    scrollbar-width: thin;
    margin-left: -10px;
}
body.default-style #illness-packages>.container .alike-card .custom-scroll::-webkit-scrollbar{
  scrollbar-color: #003b71 #dddddd;
  width: 10px;
}

body.default-style #illness-packages>.container .alike-card .custom-scroll::-webkit-scrollbar-track{
  background:#dddddd;
  -webkit-border-radius:10px;
  border-radius:10px
}

@media (max-width: 767px){
  #illness-packages header nav .nav-container{
    margin-left: -15px;
    margin-right: -15px;
    padding-top: 5px;
  }

  #illness-packages header nav .nav-container > button.badge{
    top:10px;
  }

  #illness-packages header nav .nav-container > .price-div{
    width: 100%;
    display: block;
    margin: 0 auto;
    text-align: center;
    line-height: 1;
  }

  #illness-packages header nav .nav-container > .price-div > .price{
    font-size: 1.5rem;
  }

  #illness-packages header nav .nav-container > .price-div > .price > .total-price{
    font-size: 3rem;
  }
.total-price{
    font-size: 3rem;
  }
  #illness-packages header nav .nav-container > .price-div > .installment{
    font-size: 1rem;
  }
}

@media (max-width: 340px){
  #illness-packages header nav .nav-container > .price-div {
    margin-top: 25px;
  }

  #illness-packages header nav .nav-container > .price-div > .price > .total-price {
    font-size: 2rem;
  }
}
.alert-va {
  background-color: #FFECE5;
  border: 2px solid #FF4713;
  border-radius:  8px;
  margin-bottom: 0px;
}

#illness-packages .btn-selected{
  background-color: #196bb6;
  color: white;
  text-decoration: none;
  opacity: 1
}

#illness-packages .btn-selected:hover {
  color: #fff !important;
  text-decoration: none;
}

#illness-packages .btn-nonselected{
  background-color: #5d6d7e;
  color: white;
  text-decoration: none;
  opacity: 1
}

#illness-packages .btn-nonselected:hover {
  color: #fff !important;
  text-decoration: none;
}
</style>

<template>
  <div
    id="illness-packages"
    class="pt-5"
    data-selenium-name="isTeleselling"
    :data-selenium-value="Global.isTeleselling ? 'Yes' : 'No'"
  >
    <header id="ci-packages-header">
      <nav>
      <div id="agent"
        style="padding: 5px 0px;color: #FFF;margin: 0px auto 5px auto;width: 100%;font-size: 12px;background-color: #003b71;"
        v-if="Global.brokerInfo.intermediaryReptEmail"
      >
        <div class="container">
          <div class="row">
            <div class="col-12 text-right">
              {{ hideDomain(Global.brokerInfo.intermediaryReptEmail) }}
            </div>
          </div>
        </div>
      </div>
      <div class="container">
          <div class="row">
            <div class="col">
              <div class="nav-container">
                <button
                  type="button"
                  id="back-btn" 
                  data-selenium-name="goback-fix" 
                  @click="$router.push('/ci-input');" 
                  class="badge goback-btn"
                  style="border:1px solid #6993b9;border-radius:5px;"
                >
                  <svg
                    fill="#A8CEF1"
                    height="12"
                    width="12"
                    viewBox="0 0 24 24"
                    role="img"
                    style="transform: rotate(90deg); -webkit-transform: rotate(90deg);"
                  >
                    <path
                      d="M12 19.5L.66 8.29c-.88-.86-.88-2.27 0-3.14.88-.87 2.3-.87 3.18 0L12 13.21l8.16-8.06c.88-.87 2.3-.87 3.18 0 .88.87.88 2.28 0 3.14L12 19.5z"
                    ></path>
                  </svg>
                </button>
                <!-- <button type="button" id="back-btn" data-selenium-name="goback-fix" @click="$router.push('/ci-input');" class="badge goback-btn">
                  <svg fill="#A8CEF1" height="12" width="12" viewBox="0 0 24 24" role="img" style="transform: rotate(90deg);">
                    <path d="M12 19.5L.66 8.29c-.88-.86-.88-2.27 0-3.14.88-.87 2.3-.87 3.18 0L12 13.21l8.16-8.06c.88-.87 2.3-.87 3.18 0 .88.87.88 2.28 0 3.14L12 19.5z"></path>
                  </svg>
                  {{ $t("back") }}
                </button> -->

                <!-- <button type="button" data-toggle="modal" data-selenium-name="btn-head-installment-fix" data-target="#installment-detail-modal" class="badge installment-btn" v-if="isAllowInstallment" v-on:click="eventLog('Instalment', 'Dropdown');">
                  {{ $t("instalment") }}
                  <svg fill="#A8CEF1" height="10" width="10" viewBox="1 0 24 24" role="img" style="transform: rotate(270deg);">
                    <path d="M12 19.5L.66 8.29c-.88-.86-.88-2.27 0-3.14.88-.87 2.3-.87 3.18 0L12 13.21l8.16-8.06c.88-.87 2.3-.87 3.18 0 .88.87.88 2.28 0 3.14L12 19.5z"></path>
                  </svg>
                </button> -->
                  <div class="price-div" v-if="isDisplayInstallmentPrice && totalPremium > 0">
                    <div class="price" id="nav-totalPremium">
                      <span class="small">{{$t("currency")}} </span>
                      <strong class="total-price">{{formatNumber(twelveMonthsNoDepositPremium.monthlyInstallment)}}</strong>
                      <span class="small">/{{$t("month")}}</span> <br>
                    </div>
                    <div class="mt-3" id="roojai-rewards">
                      <button
                        type="button"
                        class="badge installment-btn"
                        data-toggle="modal" data-selenium-name="btn-head-installment-fix" data-target="#installment-detail-modal" v-if="isAllowInstallment" v-on:click="eventLog('Instalment', 'Dropdown');"
                      >
                        {{ $t("instalmentOptions") }}
                        <svg
                          fill="#A8CEF1"
                          height="10"
                          width="10"
                          viewBox="1 0 24 24"
                          role="img"
                        >
                          <path
                            d="M12 19.5L.66 8.29c-.88-.86-.88-2.27 0-3.14.88-.87 2.3-.87 3.18 0L12 13.21l8.16-8.06c.88-.87 2.3-.87 3.18 0 .88.87.88 2.28 0 3.14L12 19.5z"
                          ></path>
                        </svg>
                      </button>
                    </div>
                    <!-- <div class="installment">
                      <span class="small">{{$t("instalment12months")}}</span>
                    </div> -->
                  </div>
                  <div class="price-div" v-else>
                    <div class="price" id="nav-totalPremium">
                      <span class="small">{{$t("currency")}} </span>
                        <strong class="total-price" data-selenium-name="totalPrice" :data-selenium-value="formatNumber(totalPremium)">{{formatNumber(totalPremium)}}</strong>
                      <span class="small">/{{$t("year")}}</span>
                    </div>
                  </div>
                </div>
                <div class="price-div text-center" v-if="!totalPremium > 0">
                  <h4>{{$t("packageLabel.notcover")}}</h4>
                </div>
            </div>
          </div>
        </div>
      </nav>
    </header>


    <!-- <div id="fixed-header">
      <div class="header-container">
        <button type="button" class="btn-absolute left" @click="goBack()" data-selenium-name="packages-header-goBack">
          <svg fill="#A8CEF1" height="12" width="12" viewBox="0 0 24 24" role="img" style="transform: rotate(90deg);">
              <path d="M12 19.5L.66 8.29c-.88-.86-.88-2.27 0-3.14.88-.87 2.3-.87 3.18 0L12 13.21l8.16-8.06c.88-.87 2.3-.87 3.18 0 .88.87.88 2.28 0 3.14L12 19.5z"></path>
          </svg> {{ $t("back") }}
        </button>
        <button type="button" class="btn-absolute right" @click="buynow()" data-selenium-name="packages-header-buyNow">
          {{ $t("confirmPackage") }} <svg fill="#A8CEF1" height="12" width="12" viewBox="0 0 24 24" role="img" style="transform: rotate(-90deg);">
              <path d="M12 19.5L.66 8.29c-.88-.86-.88-2.27 0-3.14.88-.87 2.3-.87 3.18 0L12 13.21l8.16-8.06c.88-.87 2.3-.87 3.18 0 .88.87.88 2.28 0 3.14L12 19.5z"></path>
          </svg>
        </button>

        <div v-if="totalPremium > 0">
          <div class="package-premium" v-if="!isInstallmentByProductFrequency" data-selenium-name="summaryPrice" :data-selenium-value="formatNumber(totalPremium)">
            <small>{{$t("currency")}} </small><strong>{{formatNumber(totalPremium)}}</strong><small>{{$t("yearly")}}</small>
          </div>
          <div class="package-premium" v-if="isInstallmentByProductFrequency" data-selenium-name="installment-payment" :data-selenium-value="formatNumber(twelveMonthsNoDepositPremium / 12)">
            <small>{{$t("currency")}} </small><strong>{{formatNumber(twelveMonthsNoDepositPremium / 12)}}</strong><small> x {{isFirstMonthInstallment ? '11' : '12'}} {{$t("months")}}</small>
          </div>
          <div class="installment-desc light-blue mb-3" v-if="isInstallmentByProductFrequency && isFirstMonthInstallment" data-selenium-name="first-installment" :data-selenium-value="formatNumber(firstInstallmentNoDepositPremium)">
            {{$t("firstInstallment")}} <small>{{$t("currency")}} </small>{{formatNumber(firstInstallmentNoDepositPremium)}}
          </div>
        </div>
        <div v-else>
          {{$t("packageLabel.notcover")}}
        </div>
      </div>
    </div> -->
    <div class="container">
      <section id="quoteNoAndPremium" class="text-center mb-4">
        <div
          id="quoteNo"
          class="text-center light-blue mb-3"
          data-selenium-name="qotation-no"
          :data-selenium-value="Global.quote.opportunityNumber"
          v-if="Global.quote.opportunityNumber && !Global.quote.opportunityNumber.toLowerCase().includes('tba')"
        >
          {{ $t("quotationNo") }}
          {{Global.quote.opportunityNumber}}
        </div>
        <!-- <div v-if="totalPremium > 0">
          <div class="package-premium" v-if="!isInstallmentByProductFrequency" data-selenium-name="summaryPrice" :data-selenium-value="formatNumber(totalPremium)">
            <small>{{$t("currency")}} </small><strong>{{formatNumber(totalPremium)}}</strong><small>{{$t("yearly")}}</small>
          </div>
          <div class="package-premium" v-if="isInstallmentByProductFrequency" data-selenium-name="installment-payment" :data-selenium-value="formatNumber(twelveMonthsNoDepositPremium / 12)">
            <small>{{$t("currency")}} </small><strong>{{formatNumber(twelveMonthsNoDepositPremium / 12)}}</strong><small> x {{isFirstMonthInstallment ? '11' : '12'}} {{$t("months")}}</small>
          </div>
          <div class="installment-desc light-blue mb-3" v-if="isInstallmentByProductFrequency && isFirstMonthInstallment" data-selenium-name="first-installment" :data-selenium-value="formatNumber(firstInstallmentNoDepositPremium)">
            {{$t("firstInstallment")}} <small>{{$t("currency")}} </small>{{formatNumber(firstInstallmentNoDepositPremium)}}
          </div>
        </div> -->
        
      </section>

      <div id="section-container">
        <!-- <img class="img-watermark" src="images/bg-ci.png" alt=""> -->
        <div class="alike-card bar-top" v-bind:class="[summaryPackages.cover.length == 0 ? 'blue-shadow' : 'red-shadow']" >
          <div class="text-center" v-if="totalPremium > 0">
            <div class="paymentOptions mb-3">
              <h4 class="mb-3" v-if="Global.defaultPreSelected == 'Cancer'">{{$t("title-cancer")}}</h4>
              <h4 class="mb-3" v-else-if="Global.defaultPreSelected == 'Dengue'">{{$t("title-dengue")}}</h4>
              <h4 class="mb-3" v-else-if="Global.defaultPreSelected == 'Cardiovascular'">{{$t("title-cardiovascular")}}</h4>
              <h4 class="mb-3" v-else>{{$t("title-critical")}}</h4>
              <div v-if="!Global.isAgentCustomerView || ( Global.isAgentCustomerView && Global.brokerInfo.useInstallment == true)" >
                <strong class="option-installment" v-bind:class="{'active': Global.paymentFrequency == '12 months, no deposit', 'disabled': !isAllowInstallment}">{{$t("month")}}</strong>
                <div class="switch">
                  <a class="slider round" v-bind:class="{'disabled': !isAllowInstallment, 'annual': Global.paymentFrequency == 'Yearly'}" data-selenium-name="paymentOption" :data-selenium-value="Global.paymentFrequency" @click="switchPaymentFrequency( Global.paymentFrequency == 'Yearly' ? '12 months, no deposit' : 'Yearly' )"></a>
                </div>
                <strong class="option-annual" v-bind:class="{'active': Global.paymentFrequency == 'Yearly', 'disabled': !isAllowInstallment}">{{$t("annual")}} <small>({{$t("economical")}} {{Math.round((Global?.packages?.twelveMonthsNoDepositPaymentLoadingFactor?.value-1)/(Global?.packages?.twelveMonthsNoDepositPaymentLoadingFactor?.value)*100)}}%)</small> </strong>
              </div>
            </div>

            <div class="package-premium" v-if="isDisplayInstallmentPrice">
              <div class="price" id="nav-totalPremium">
                <span class="small">{{$t("currency")}} </span>
                <strong class="total-price">
                  {{formatNumber(twelveMonthsNoDepositPremium.monthlyInstallment)}}
                </strong> 
                <span class="small">/{{$t("month")}}</span> <br>
                <!-- <strong class="total-price">Total : {{formatNumber(twelveMonthsNoDepositPremium.totalInstallment)}}</strong> -->
              </div>
              <!-- <div class="installment">
                <span class="small">{{$t("instalment12months")}}</span>
              </div> -->
            </div>

            <div class="package-premium" v-else>
              <div class="price" id="nav-totalPremium">
                <span class="small">{{$t("currency")}} </span>
                  <strong class="total-price" data-selenium-name="totalPrice" :data-selenium-value="formatNumber(totalPremium)">{{formatNumber(totalPremium)}}</strong>
                <span class="small">/{{$t("year")}}</span>
              </div>
            </div>
          </div>
          <div class="text-center" v-if="!totalPremium > 0">
            <h4>{{$t("packageLabel.notcover")}}</h4>
          </div>

          
        <section v-for="(product , index) in productFilter" :id="'section-' + product.name.value" class="section" v-bind:key="index" v-show="index >= 0 || showAllPackage">
          <div class="alike-card-item">
            <!-- <div class="paymentOptions">
              <strong class="option" v-bind:class="{'active' : Global.paymentFrequency != 'Yearly', 'disabled': isDisableSwitchInstallment(product)}">{{$t("monthly")}}</strong>
              <div class="switch">
                <a class="slider round" :data-selenium-name="'paymentOptions-'+ product.name.value" :data-selenium-value="Global.paymentFrequency"
                  v-bind:class="{'annual' : (Global.paymentFrequency == 'Yearly' || product.name.value == 'Covid19' || !Global.selectedPackages[product.name.value]), 'disabled': isDisableSwitchInstallment(product) }"
                  @click="product.paymentFrequency = (Global.paymentFrequency == 'Yearly' ? '12 months, no deposit' : 'Yearly'); switchPaymentFrequency( Global.paymentFrequency == 'Yearly' ? '12 months, no deposit' : 'Yearly' ); eventLog('Instalment-' + product.name.value, Global.paymentFrequency);">
                </a>
              </div>
              <strong class="option" v-bind:class="{'active' : Global.paymentFrequency == 'Yearly' || product.name.value == 'Covid19', 'disabled': !Global.selectedPackages[product.name.value]}">{{$t("annual")}}</strong>
            </div> -->

            <!-- <div class="text-center packagePremium mt-3 mb-2">
              <template v-if="Global.selectedPackages[product.name.value]">
                <small>{{$t("currency")}} </small><strong :data-selenium-name="'summaryPrice-'+ product.name.value + '-' + Global.paymentFrequency" :data-selenium-value="getPriceByProductOption(product, Global.selectedPackages[product.name.value])">{{getPriceByProductOption(product, Global.selectedPackages[product.name.value])}}</strong><small v-if="Global.paymentFrequency != 'Yearly'">/{{$t("month")}}</small><small v-else>{{$t("yearly")}}</small>
              </template>
              <template v-else>
                <small><strong>{{$t("packageLabel.notcover")}}</strong></small>
              </template>
            </div> -->
            <div class="coverage2text mt-4">
              <!-- {{product.name.value}} -->
              <div class="d-flex">
                <img :src="'/images/' + product.name.value +'.png'" alt="" height="50">
                <div class="flex-column ml-3 mt-1">
                  <h3 class="h6" :data-selenium-name="'selected-package-' + product.name.value">
                    {{ $t((!("Covid19").includes(product.name.value) ? product.name.value.toLowerCase() : 'covid')+".title") }} 
                    <br>
                    <small v-if="product.name.value != 'Dengue'"> ({{$t("packageLabel.reimbursement")}})</small> 
                    <small v-else-if="product.name.value == 'Dengue'">({{$t("packageLabel.cashless")}})</small>
                  </h3>
                </div>
              </div>
              <!-- <br> -->
              <div class="item-list mb-3 mt-3">
                <!-- <span class="glyphicons glyphicons-arrow-left" ></span> -->
                <div class="item">
                  <span class="glyphicons pl-2" v-bind:class="[Global.selectedPackages[product.name.value] ? 'glyphicons-ok' : 'glyphicons-remove']"></span>
                  {{ $t((!("Covid19").includes(product.name.value) ? product.name.value.toLowerCase() : 'covid')+".coverage") }}
                  <a href="javascript:void(0)" class="question-mark-red" data-toggle="tooltip" data-placement="top" :title="$t(product.name.value.toLowerCase()+'.tooltips')" :data-original-title="$t( product.name.value.toLowerCase()+'.tooltips')">?</a> 
                  <br>
                  <!-- {{product?.optionList?.option}} -->
                  <div class="custom-scroll mt-2" :id="'custom-scroll-' + product.name.value">
                      <button class="btn btn-left rounded-circle" @click="scroll_left(product.name.value)">
                        <svg
                          fill="#888888"
                          height="12"
                          viewBox="0 0 24 24"
                          role="img"
                          style="transform: rotate(90deg); -webkit-transform: rotate(90deg);"
                        >
                          <path
                            d="M12 19.5L.66 8.29c-.88-.86-.88-2.27 0-3.14.88-.87 2.3-.87 3.18 0L12 13.21l8.16-8.06c.88-.87 2.3-.87 3.18 0 .88.87.88 2.28 0 3.14L12 19.5z"
                          ></path>
                        </svg> 
                      </button>
                      <button class="btn btn-right rounded-circle" @click="scroll_right(product.name.value)">
                        <svg
                          fill="#888888"
                          height="12"
                          viewBox="0 0 24 24"
                          role="img"
                          style="transform: rotate(270deg); -webkit-transform: rotate(270deg);"
                        >
                          <path
                            d="M12 19.5L.66 8.29c-.88-.86-.88-2.27 0-3.14.88-.87 2.3-.87 3.18 0L12 13.21l8.16-8.06c.88-.87 2.3-.87 3.18 0 .88.87.88 2.28 0 3.14L12 19.5z"
                          ></path>
                        </svg> 
                      </button>
                      <!-- <img src="images/right.svg" class="arrow-right"  alt=""> -->
                    <div class="item-wrapper" id="item-wrapper">
                      <a href="javascript:void(0)" class="item" v-bind:class="{active: !Global.selectedPackages[product.name.value], notCoveredDisable: !isAllowSelectNotCovered}"
                        @click="selectNotCover(product); eventLog(product.name.value, 'NotCover');checkHighestSumInsured();" :data-selenium-name="'package-'+ product.name.value" data-selenium-value="0">
                        <span class="circle-check"></span>
                        <span class="desc" v-bind:class="{active: !Global.selectedPackages[product.name.value], notCoveredDisable: !isAllowSelectNotCovered}">&nbsp;</span>
                        <span class="cover-value">{{$t("packageLabel.notcover")}}</span>
                        <span class="price" v-show="Global.selectedPackages[product.name.value] !== null">
                          <small>{{$t("currency")}} </small>0
                        </span>
                      </a>
                      <a href="javascript:void(0)" class="item" v-for="(option, index) in product?.optionList?.option" v-bind:class="{active : Global?.selectedPackages[product.name.value]?.premiumBeforeTaxes?.value === option.premiumBeforeTaxes.value }"
                        @click="Global.selectedPackages[product.name.value] = option;checkHighestSumInsured(); eventLog(product.name.value, option.sumAssured.value);" v-bind:key="product.name.value+'-option-' + index" :data-selenium-name="'package-'+product.name.value" :data-selenium-value="index+1">
                        <span class="circle-check"></span>
                        <span class="desc" v-if="product?.name?.value =='Dengue'">{{getPackageLabel(product?.name?.value, index)}}</span>
                        <span class="desc-dengue-1 mb-1" v-if="product?.name?.value =='Dengue'">{{ option?.coverages?.coverage[0]?.additionalCoverageName?.value }}</span>
                        <span class="cover-value" v-if="product?.name?.value =='Dengue'"><small>{{$t("currency")}} </small>{{ formatNumber(option?.coverages?.coverage[0]?.additionalCoverageSumAssured?.value) }}</span>
                        <span class="cover-value" v-else><small>{{$t("currency")}} </small>{{ formatNumber(option?.sumAssured?.value) }}</span>
                        <!-- <span class="cover-value"><small>{{$t("currency")}} </small>{{ formatNumber(option?.sumAssured?.value) }}</span> -->

                        <span class="desc-dengue-2 mb-1" v-if="product?.name?.value =='Dengue'">
                          {{ option?.coverages?.coverage[1]?.additionalCoverageName?.value }}
                        </span>
                        <span class="cover-value" style="font-size:10px; padding-top:3px;" v-if="product?.name?.value =='Dengue'">
                          <small>{{$t("currency")}} </small>
                          {{ formatNumber(option?.coverages?.coverage[1]?.additionalCoverageSumAssured?.value) }}
                        </span>
                        <span class="price" v-show="Global.selectedPackages[product.name.value]?.premiumBeforeTaxes?.value !== option.premiumBeforeTaxes.value">
                          <!-- <small> {{$t("currency")}} </small>{{getPriceByProductOption(product, option)}}<small v-if="Global.paymentFrequency != 'Yearly'">/{{$t("month")}}</small><small v-else>{{$t("yearly")}}</small>
                           -->
                           <span class="glyphicons" v-bind:class="[premiumShow(getPriceByProductOptionPremi(product, option), Global.selectedPackages[product.name.value]?.premiumBeforeTaxes?.value, isDisplayInstallmentPrice).isMinus == true ? 'glyphicons-minus' : 'glyphicons-plus']"></span>
                          <small>{{$t("currency")}} </small> {{formatNumber(premiumShow(getPriceByProductOptionPremi(product, option), Global.selectedPackages[product.name.value]?.premiumBeforeTaxes?.value, isDisplayInstallmentPrice).premium)}}
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
                <!-- <div v-if="Global.selectedPackages[product.name.value] && Global.selectedPackages[product.name.value].coverages?.coverage?.length > 0">
                  <div class="item" v-for="(coverage, index) in Global.selectedPackages[product.name.value].coverages.coverage" v-bind:key="product.name.value+'-coverage-' + index">
                    <template v-if="!['Coma','Skin cancer - Melanoma'].includes(coverage.additionalCoverageName.value)">
                      <span class="glyphicons glyphicons-ok"></span>{{coverageTranslate(coverage.additionalCoverageName.value)}}
                      <br />{{product.name.value != 'Cancer' ? getCoverageAdditionalUpto(coverage.additionalCoverageName.value) : ''}}
                      <strong>
                        <small>{{$t("currency")}} </small>{{formatNumber(coverage.additionalCoverageSumAssured.value)}}
                      </strong>
                    </template>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </section>
        </div>

        <div class="alike-card mt-5" v-bind:class="[summaryPackages.cover.length == 0 ? 'blue-shadow' : 'red-shadow']" v-if="Global.defaultPreSelected == 'Dengue' || Global.defaultPreSelected == 'Cardiovascular'">
          <div v-if="totalPremium > 0" >
            <div class="paymentOptions other-package">
              <h4 class="mb-3">{{$t("title-otherPackage")}}</h4>
              <p>{{$t("text-otherPackage")}}</p>
            </div>
          </div>
        <section v-for="(product , index) in productSplice" :id="'section-' + product.name.value" class="section" v-bind:key="index" v-show="index >= 0 || showAllPackage">
          <div class="alike-card-item other-package">
            <!-- <div class="paymentOptions">
              <strong class="option" v-bind:class="{'active' : Global.paymentFrequency != 'Yearly', 'disabled': isDisableSwitchInstallment(product)}">{{$t("monthly")}}</strong>
              <div class="switch">
                <a class="slider round" :data-selenium-name="'paymentOptions-'+ product.name.value" :data-selenium-value="Global.paymentFrequency"
                  v-bind:class="{'annual' : (Global.paymentFrequency == 'Yearly' || product.name.value == 'Covid19' || !Global.selectedPackages[product.name.value]), 'disabled': isDisableSwitchInstallment(product) }"
                  @click="product.paymentFrequency = (Global.paymentFrequency == 'Yearly' ? '12 months, no deposit' : 'Yearly'); switchPaymentFrequency( Global.paymentFrequency == 'Yearly' ? '12 months, no deposit' : 'Yearly' ); eventLog('Instalment-' + product.name.value, Global.paymentFrequency);">
                </a>
              </div>
              <strong class="option" v-bind:class="{'active' : Global.paymentFrequency == 'Yearly' || product.name.value == 'Covid19', 'disabled': !Global.selectedPackages[product.name.value]}">{{$t("annual")}}</strong>
            </div> -->

            <!-- <div class="text-center packagePremium mt-3 mb-2">
              <template v-if="Global.selectedPackages[product.name.value]">
                <small>{{$t("currency")}} </small><strong :data-selenium-name="'summaryPrice-'+ product.name.value + '-' + Global.paymentFrequency" :data-selenium-value="getPriceByProductOption(product, Global.selectedPackages[product.name.value])">{{getPriceByProductOption(product, Global.selectedPackages[product.name.value])}}</strong><small v-if="Global.paymentFrequency != 'Yearly'">/{{$t("month")}}</small><small v-else>{{$t("yearly")}}</small>
              </template>
              <template v-else>
                <small><strong>{{$t("packageLabel.notcover")}}</strong></small>
              </template>
            </div> -->
            <div class="coverage2text mt-4">
              <div class="d-flex">
                <img :src="'/images/' + product.name.value +'.png'" alt="" height="50">
                <div class="flex-column ml-3 mt-1">
                  <h3 class="h6" :data-selenium-name="'selected-package-' + product.name.value">
                    {{ $t((!("Covid19").includes(product.name.value) ? product.name.value.toLowerCase() : 'covid')+".title") }} 
                    <br>
                    <small v-if="product.name.value != 'Dengue'"> ({{$t("packageLabel.reimbursement")}})</small> 
                    <small v-else-if="product.name.value == 'Dengue'">({{$t("packageLabel.cashless")}})</small>
                  </h3>
                </div>
              </div>
              <br>
              <div class="item-list mb-3">
                <!-- <span class="glyphicons glyphicons-arrow-left" ></span> -->
                <div class="item"> 
                  <span class="glyphicons pl-2" v-bind:class="[Global.selectedPackages[product.name.value] ? 'glyphicons-ok' : 'glyphicons-remove']"></span>
                  {{ $t((!("Covid19").includes(product.name.value) ? product.name.value.toLowerCase() : 'covid')+".coverage") }}
                  <a href="javascript:void(0)" class="question-mark-red" data-toggle="tooltip" data-placement="top" :title="$t(product.name.value.toLowerCase()+'.tooltips')" :data-original-title="$t( product.name.value.toLowerCase()+'.tooltips')">?</a>
                  <br>
                  <!-- {{product?.optionList?.option}} -->
                  <div class="custom-scroll mt-2" :id="'custom-scroll-' + product.name.value">
                    <!-- Product : {{product}} <br> -->
                    
                    <!-- {{Global.selectedPackages}} -->
                    <button class="btn btn-left rounded-circle" @click="scroll_left(product.name.value)">
                        <svg
                          fill="#888888"
                          height="12"
                          viewBox="0 0 24 24"
                          role="img"
                          style="transform: rotate(90deg); -webkit-transform: rotate(90deg);"
                        >
                          <path
                            d="M12 19.5L.66 8.29c-.88-.86-.88-2.27 0-3.14.88-.87 2.3-.87 3.18 0L12 13.21l8.16-8.06c.88-.87 2.3-.87 3.18 0 .88.87.88 2.28 0 3.14L12 19.5z"
                          ></path>
                        </svg> 
                      </button>
                      <button class="btn btn-right rounded-circle" @click="scroll_right(product.name.value)">
                        <svg
                          fill="#888888"
                          height="12"
                          viewBox="0 0 24 24"
                          role="img"
                          style="transform: rotate(270deg); -webkit-transform: rotate(270deg);"
                        >
                          <path
                            d="M12 19.5L.66 8.29c-.88-.86-.88-2.27 0-3.14.88-.87 2.3-.87 3.18 0L12 13.21l8.16-8.06c.88-.87 2.3-.87 3.18 0 .88.87.88 2.28 0 3.14L12 19.5z"
                          ></path>
                        </svg> 
                      </button>
                    <div class="item-wrapper" id="item-wrapper">
                      <a href="javascript:void(0)" class="item" v-bind:class="{active: !Global.selectedPackages[product.name.value], notCoveredDisable: !isAllowSelectNotCovered}"
                        @click="selectNotCover(product);checkHighestSumInsured(); eventLog(product.name.value, 'NotCover');" :data-selenium-name="'package-'+ product.name.value" data-selenium-value="0">
                        <span class="circle-check"></span>
                        <span class="desc" v-bind:class="{active: !Global.selectedPackages[product.name.value], notCoveredDisable: !isAllowSelectNotCovered}">&nbsp;</span>
                        <span class="cover-value">{{$t("packageLabel.notcover")}}</span>
                        <span class="price" v-show="Global.selectedPackages[product.name.value] !== null">
                          <small>{{$t("currency")}} </small>0
                        </span>
                      </a>
                      <a href="javascript:void(0)" class="item" v-for="(option, index) in product?.optionList?.option" v-bind:class="{active : Global?.selectedPackages[product.name.value]?.premiumBeforeTaxes?.value === option.premiumBeforeTaxes.value }"
                        @click="Global.selectedPackages[product.name.value] = option; checkHighestSumInsured();eventLog(product.name.value, option.sumAssured.value);" v-bind:key="product.name.value+'-option-' + index" :data-selenium-name="'package-'+product.name.value" :data-selenium-value="index+1">
                        <span class="circle-check"></span>
                        <span class="desc">{{getPackageLabel(product?.name?.value, index)}}</span>
                        <span class="desc-dengue-1 mb-1" v-if="product?.name?.value =='Dengue'">{{ option?.coverages?.coverage[0]?.additionalCoverageName?.value }}</span>
                        <span class="cover-value" v-if="product?.name?.value =='Dengue'"><small>{{$t("currency")}} </small>{{ formatNumber(option?.coverages?.coverage[0]?.additionalCoverageSumAssured?.value) }}</span>
                        <span class="cover-value" v-else><small>{{$t("currency")}} </small>{{ formatNumber(option?.sumAssured?.value) }}</span>
                        <!-- <span class="cover-value"><small>{{$t("currency")}} </small>{{ formatNumber(option?.sumAssured?.value) }}</span> -->

                        <span class="desc-dengue-2 mb-1" v-if="product?.name?.value =='Dengue'">
                          {{ option?.coverages?.coverage[1]?.additionalCoverageName?.value }}
                        </span>
                        <span class="cover-value" style="font-size:10px; padding-top:3px;" v-if="product?.name?.value =='Dengue'">
                          <small>{{$t("currency")}} </small>
                          {{ formatNumber(option?.coverages?.coverage[1]?.additionalCoverageSumAssured?.value) }}
                        </span>
                        <span class="price" v-show="Global.selectedPackages[product.name.value]?.premiumBeforeTaxes?.value !== option.premiumBeforeTaxes.value">
                          <!-- <small> {{$t("currency")}} </small>{{getPriceByProductOption(product, option)}}<small v-if="Global.paymentFrequency != 'Yearly'">/{{$t("month")}}</small><small v-else>{{$t("yearly")}}</small>
                           -->
                           <span class="glyphicons" v-bind:class="[premiumShow(getPriceByProductOptionPremi(product, option), Global.selectedPackages[product.name.value]?.premiumBeforeTaxes?.value, isDisplayInstallmentPrice).isMinus == true ? 'glyphicons-minus' : 'glyphicons-plus']"></span>
                          <small>{{$t("currency")}} </small> {{formatNumber(premiumShow(getPriceByProductOptionPremi(product, option), Global.selectedPackages[product.name.value]?.premiumBeforeTaxes?.value, isDisplayInstallmentPrice).premium)}}
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
                <!-- <div v-if="Global.selectedPackages[product.name.value] && Global.selectedPackages[product.name.value].coverages?.coverage?.length > 0">
                  <div class="item" v-for="(coverage, index) in Global.selectedPackages[product.name.value].coverages.coverage" v-bind:key="product.name.value+'-coverage-' + index">
                    <template v-if="!['Coma','Skin cancer - Melanoma'].includes(coverage.additionalCoverageName.value)">
                      <span class="glyphicons glyphicons-ok"></span>{{coverageTranslate(coverage.additionalCoverageName.value)}}
                      <br />{{product.name.value != 'Cancer' ? getCoverageAdditionalUpto(coverage.additionalCoverageName.value) : ''}}
                      <strong>
                        <small>{{$t("currency")}} </small>{{formatNumber(coverage.additionalCoverageSumAssured.value)}}
                      </strong>
                    </template>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </section>
        </div>
        <!-- <div class="roojai-suggestion text-center my-5" v-if="showAllPackage !== true && Global.productList.length > 1">
          <div class="tooltip2extra fadeIn">
            <div class="tooltip2extra">
              <i18n-t tag="div" keypath="seeMoreProducts">
                <span>{{$t((!("Covid19").includes(Global.productList[1].name.value) ? Global.productList[1].name.value.toLowerCase() : 'covid')+".titleShort")}}</span>
              </i18n-t>
              <div class="suggestion-options">
                <button type="button" class="btn" data-selenium-name="suggestion-options-yes" @click="Global.productConfig.showAllPackage = true; Global.selectedPackages[Global.productList[1].name.value] = Global.productList[1]?.optionList?.option[0]">{{"seeMoreProductsYes")}}</button>
                <button type="button" class="btn" data-selenium-name="suggestion-options-no" @Click="Global.productConfig.showAllPackage = false;moveTo('#package-summary')">{{$t("seeMoreProductsNo")}}</button>
              </div>
            </div>
            <div class="arrow2extra"></div>
          </div>
          <picture>
            <source media="(min-width: 1280px)" srcset="images/gentle-remind.png">
            <img class="lazy" src="data:image/png;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=" data-src="images/gentle-remind.png" alt="คุณอยากดูความคุ้มครองเกี่ยวกับ COVID-19 ไปด้วยเลยไหม!">
          </picture>
        </div> -->
      </div>
    </div>

    <div id="package-summary-container" style="min-height: 90vh;">
      <div class="container">
        <div class="row">
          <div class="col frame-summary">
            <div id="package-summary">
              <!-- <div class="text-center car2show">
                {{ $t("yourIllnessPackage") }}
              </div> -->
              <div class="p-3 frame-coverage">
                <h4 class="text-center mb-1">
                  <strong>{{ $t("yourCoverageInsurance") }}</strong>
                </h4>
              </div>
              
              <div class="text-center mt-3 mb-3">
                <picture>
                  <source
                    media="(min-width:640px)"
                    srcset="images/sum-illness2x.png"
                  />
                  <source
                    media="(max-width:415px)"
                    srcset="images/sum-illness.png"
                  />
                  <img class="lazy" src="data:image/png;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=" data-src="images/sum-illness.png" alt="Summary coverage" />
                </picture>
              </div>
              <center v-if="summaryPackages.cover.length > 0">
                <h5 class="text-center mb-1">
                  <strong>{{ $t("selected") }}</strong>
                </h5>
                <!-- <button class="btn btn-selected" v-bind:class="{'disabled': true}" :disabled="true">
                  {{ $t("selected") }}
                </button> -->
              </center>
              <br>
              <div style="position: relative; margin-bottom: 4rem;">
                <div class="mb-4" v-for="(selectedPackage, key) in summaryPackages.cover" v-bind:key="'selectedPackage-'+ key">
                  <h3 class="h6">{{$t(selectedPackage.text)}}</h3>
                  <div class="item-list pl-3">
                      <div class="item active" v-for="(coverage,index) in selectedPackage.coverages" v-bind:key="'selectedPackage-coverages-'+ index">
                        <span class="glyphicons glyphicons-ok" ></span>
                        {{$t(coverage?.text)}} {{coverage?.textUpto ? $t(coverage?.textUpto) : ""}} <span class="small">{{$t("currency")}} </span> <strong>{{formatNumber(coverage?.value)}}</strong>
                      </div>
                  </div>
                </div>
              </div>
              <center v-if="summaryPackages.uncover.length > 0">
                <h5 class="text-center mb-1">
                  <strong>{{ $t("nonselected") }}</strong>
                </h5>
                <!-- <button class="btn btn-nonselected" v-bind:class="{'disabled': true}" :disabled="true">
                  {{ $t("nonselected") }}
                </button> -->
              </center>
              <br>
              <div style="position: relative; margin-bottom: 4rem;">
                <div class="mb-4" v-for="(selectedPackage, key) in summaryPackages.uncover" v-bind:key="'selectedPackage-'+ key">
                  <h3 class="h6">{{$t(selectedPackage.text)}}</h3>
                  <div class="item-list pl-3">
                      <div class="item inactive nonselected" v-for="(coverage,index) in selectedPackage.coverages" v-bind:key="'selectedPackage-coverages-'+ index">
                        <span class="glyphicons glyphicons-remove" ></span>
                        {{$t(coverage?.text)}}
                      </div>
                  </div>
                </div>
              </div>
              <!-- <div style="position: relative; margin-bottom: 4rem;">
                <div class="mb-4" v-for="(selectedPackage, key) in summaryPackages" v-bind:key="'selectedPackage-'+ key">
                  <h3 class="h6">{{$t(selectedPackage.text)}}</h3>
                  <div class="item-list pl-3">
                      <div class="item active" v-for="(coverage,index) in selectedPackage.coverages" v-bind:key="'selectedPackage-coverages-'+ index">
                        <span class="glyphicons glyphicons-ok" ></span>
                        {{$t(coverage?.text)}} {{coverage?.textUpto ? $t(coverage?.textUpto) : ""}} <span class="small">{{$t("currency")}} </span> <strong>{{formatNumber(coverage?.value)}}</strong>
                      </div>
                  </div>
                </div>
              </div> -->
              <div class="row" v-if="!Global.isAgentTraditionalBroker">
                <div class="col-12 mb-2 order-sm-1" v-if="Global.isTeleselling === false">
                  <button
                    id="buy-now"
                    type="button"
                    data-selenium-name="buyNow"
                    :disabled="totalPremium < 1"
                    class="btn btn-block btn-lg btn-link-default buy-now"
                    @click="buynow(); eventLog('Buy_now', 'Details');"
                  >
                    {{ $t("confirmPackage") }}
                  </button>
                </div>
                <div class="col-12 mb-2">
                  <!-- <span style="margin:auto; display:table; color:rgb(255, 80, 17);">
                    {{ $t("eQuoteTitle") }}
                  </span> -->
                </div>
                <div class="col-12 col-md-7 mb-2 order-sm-3">
                  <a
                    href="javascript:void(0)"
                    id="roojai-call-back"
                    data-toggle="modal"
                    data-target="#roojai-call-back-modal"
                    data-selenium-name="roojai-call-back"
                    type="button"
                    class="btn btn-callMeback btn-block btn-lg"
                    style="border-color:#ff5011"
                    v-on:click="eventLog('call_me_back', 'text');"
                    ><span class="glyphicons glyphicons-earphone"></span>&nbsp;{{ $t("eQuoteTitleCall") }}
                  </a>
                </div>

                <div class="col-12 col-md-5 mb-2 order-sm-2 order-last">
                  <a
                    href="javascript:void(0)"
                    id="create-policy"
                    data-selenium-name="create-policy"
                    data-toggle="modal"
                    data-target="#email-policy-modal"
                    type="button"
                    class="btn btn-email btn-block btn-lg"
                    style="border-color:#ff5011"
                    v-on:click="eventLog('Receive_email', 'Details'); eventTracking('Oppo','quotationResultPackages', 'Opportunity_email_quote', recognizeProduct(Global.ciLabel));"
                    ><span class="glyphicons glyphicons-envelope" style="top:-1px"></span> &nbsp;{{ $t("eQuoteTitle") }}
                  </a>
                </div>
                <!-- <div class="col-12 col-md-4 mb-2">
                    <a
                      href="javascript:void(0)"
                      id="create-policy"
                      data-selenium-name="floating-Whatsapp"
                      data-toggle="modal"
                      data-target="#roojai-wa-modal"
                      type="button"
                      class="btn btn-link-blue btn-block btn-lg"
                      v-on:click="eventLog('whatsapp', 'Fixed_bottom');"
                      >{{ $t("eQuoteWhatsapp")}}
                    </a>
                  </div> -->
              </div>
              
              <div class="row" v-else>
                <div class="col-12 col-md-7 mb-2">
                  <a
                    href="javascript:void(0)"
                    id="create-policy"
                    data-selenium-name="create-policy"
                    data-toggle="modal"
                    data-target="#email-policy-modal"
                    type="button"
                    class="btn btn-link-default btn-block btn-lg orange-text"
                    style="border-width: 2px; border-color: rgb(255, 80, 17); font-size:18px"
                    v-on:click="eventLog('Receive_email', 'Details');"
                    >{{ $t("eQuote") }}</a
                  >
                </div>
                <div
                  class="col-12 col-md-6 mb-4"
                  v-if="Global.isTeleselling === false"
                >
                  <button
                    id="buy-now"
                    type="button"
                    data-selenium-name="buyNow"
                    :disabled="totalPremium < 1"
                    class="btn btn-block btn-lg btn-link-default buy-now"
                    @click="buynow(); eventLog('Buy_now', 'Details');"
                  >
                    {{ $t("confirmPackage") }}
                  </button>
                </div>
              </div>
              <!-- <div class="clearfix pb-5"></div> -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal modal-v1 fade" id="roojai-call-back-modal" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content white-bg">
          <div class="modal-header">
            <!-- <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              data-selenium-name="callmeback-close"
            >
              X
            </button> -->
            <h5 class="modal-title mt-4 text-center h6">
              {{ Global.isAgentCustomerView ? $t("eQuoteAgent") : $t("callMeBack")}}
            </h5>
          </div>
          <div class="modal-body">
            <div class="mb-2 text-muted text-center" v-if="Global.isAgentCustomerView">
              {{ (Global.isAgentCustomerView ? $t("eQuoteExplainViaMobileAgent") :$t("eQuoteExplainViaMobile")) }}
            </div>
            <input
              type="tel"
              class="form-control input-lg"
              maxlength="15"
              v-model.trim="Global.phaccount.phoneNumber"
              :placeholder="$t('yourMobilePhone')"
              data-selenium-name="callMeBack-mobile"
            />
            <div class="text-muted mt-2">
              <span v-if="!Global.isAgentCustomerView" class="glyphicons glyphicons-info-sign" style="color:#003b71"></span>
              &nbsp;<span v-if="!Global.isAgentCustomerView">{{ $t("pleaseEnterYourMobileNumber") }}</span>
            </div>
          </div>
          <div class="modal-footer d-flex flex-column flex-md-row justify-content-center d px-2 px-md-3 pb-2">
            <button
              type="button"
              class="btn btn-cancel mb-2 mb-md-0 ml-0 ml-md-n1 mr-0 mr-md-1 w-50"
              data-dismiss="modal"
              aria-label="Close"
              data-selenium-name="callmeback-close"
            >
              {{ $t("back") }}
            </button>
            <button
              type="button"
              class="btn btn-confirm ml-0 ml-md-1 mr-0 mr-md-n1 w-50"
              :disabled="!isValidPolicyMobile"
              @click="callMeBack(); eventTracking('Oppo','quotationResultPackages', 'Opportunity_call_me_back', recognizeProduct(Global.ciLabel));"
              data-selenium-name="callmeback-submit"
            >
              {{ $t("roojaiCallmeback") }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal modal-v1 fade" id="payment-change-to-yearly" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content white-bg">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" data-selenium-name="payment-change-close" v-on:click="closePaymentChangeModal()">X</button>
            <h5 class="modal-title mt-4 text-center h5">
                {{$t("paymentChangeToYearlyHeader")}}
              </h5>
          </div>
          <div class="modal-body text-center">
            {{$t("paymentChangeToYearly")}} <span class="small" v-if="!isTHB">{{$t("currency")}} </span>{{formatNumber(Global.CIminInstallmentTotalPremium)}}
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-primary w-100"
              data-selenium-name="payment-change-close-btn"
              data-dismiss="modal"
              v-on:click="closePaymentChangeModal()"
            >
              {{ $t("close") }}
            </button>
        </div>
        </div>
      </div>
    </div>

    <div
      class="modal modal-v1 fade"
      id="email-policy-modal"
      data-backdrop="static"
      tabindex="-1"
    >
      <div class="modal-dialog">
        <div class="modal-content white-bg">
          <div class="modal-header">
            <h5 class="modal-title text-center h5" v-if="!Global.isAgentCustomerView">
              {{ $t("eQuote") }}
            </h5>
            <h5 class="modal-title text-center h5" v-if="Global.isAgentCustomerView && !emailPolicy.email && !emailPolicy.mobile">
              {{ $t("eQuote") }}
            </h5>
            <h5 class="modal-title text-center h5" v-if="Global.isAgentCustomerView && emailPolicy.email && !emailPolicy.mobile">
              {{ $t("eQuoteAgent") }}
            </h5>
            <h5 class="modal-title text-center h5" v-if="Global.isAgentCustomerView && emailPolicy.email && emailPolicy.mobile">
              {{ $t("eQuoteAmazing") }}
            </h5>
          </div>
          
          <div class="modal-body pb-5">
            <div id="emailPolicy-email" v-if="!emailPolicy.email">
              <input
                type="text"
                v-if="!Global.isAgentCustomerView"
                class="form-control input-lg"
                v-bind:class="{ 'text-center': isValidPolicyEmail }"
                placeholder="sample@email.com"
                v-model.trim="Global.phaccount.email"
                data-selenium-name="createPolicy-email"
              />
              <input
                v-if="Global.isAgentCustomerView"
                type="text"
                class="form-control input-lg"
                v-bind:class="{ 'text-center': isValidPolicyEmail }"
                placeholder="sample@email.com"
                v-model.trim="Global.phaccount.email"
                data-selenium-name="createPolicy-email"
              />
              <div
                class="btn-group-toggle label-checkbox mt-2"
                data-toggle="buttons"
              >
                <label v-if="!Global.isAgentCustomerView" class="btn btn-check" style="font-size:13px;color:#000">
                  <input
                    type="checkbox"
                    autocomplete="off"
                    @click="
                      Global.phaccount.PersonHasOptedOutOfEmail = !Global
                        .phaccount.PersonHasOptedOutOfEmail
                    "
                    :checked="Global.phaccount.PersonHasOptedOutOfEmail"
                    data-selenium-name="createPolicy-PersonHasOptedOutOfEmail"
                    :data-selenium-value="
                      !Global.phaccount.PersonHasOptedOutOfEmail ? 'No' : 'Yes'
                    "
                  />
                  {{ $t("emailOptedOut") }}
                </label>
                <div v-if="!Global.isAgentCustomerView" style="color: #FF5011!important; font-size:13px;">
                  {{ $t("getMoreDetails") }}
                </div>
                <div :class="{'text-center':Global.isAgentCustomerView}" style="font-size:16px;margin-top:1rem;font-weight:bold;">
                  {{ Global.isAgentCustomerView ? $t("getEmailAgent") : $t("getEmail") }}
                </div>
              </div>
            </div>

            <div
              id="emailPolicy-mobile"
              v-if="emailPolicy.email && !emailPolicy.mobile"
            >
              <div class="mb-2 text-muted text-center">
                {{ (Global.isAgentCustomerView ? $t("eQuoteExplainViaMobileAgent") :$t("eQuoteExplainViaMobile")) }}
              </div>
              <input
                type="tel"
                class="form-control input-lg"
                v-bind:class="{ 'text-center': isValidPolicyMobile }"
                :placeholder="$t('yourMobilePhone')"
                maxlength="15"
                v-model.trim="Global.phaccount.phoneNumber"
                data-selenium-name="createPolicy-mobile"
              />
              <div class="mt-4 text-center">
                <a
                  href="javascript:void(0)"
                  style="color: #FF5011!important;"
                  data-selenium-name="btn-createPolicy-skip"
                  @click="emailPolicyAction().skipMobile()"
                  >{{ $t("eQuoteSkipNext") }}</a
                >
              </div>
              <div class="mt-4 text-center" v-if="!Global.isAgentCustomerView">
                {{ $t("eQuoteNofee") }}
              </div>
            </div>

            <div
              id="emailPolicy-finished"
              v-if="emailPolicy.email && emailPolicy.mobile"
            >
              <div class="mt-4 text-center">
                <h6 class="text-default" v-if="!Global.isAgentCustomerView">{{ $t("eQuoteAmazing") }}</h6>
                <div>
                  {{ (Global.isAgentCustomerView ? $t("eQuoteDetailAgent") : $t("eQuoteDetail")) }}
                </div>
              </div>
            </div>
          </div>
          
          <div
            class="modal-footer d-flex flex-column flex-md-row justify-content-center d px-2 px-md-3 pb-2"
            v-bind:key="'emailPolicy-email'"
            v-if="!emailPolicy.email"
          >
            <button
              type="button"
              class="btn btn-cancel mb-2 mb-md-0 ml-0 ml-md-n1 mr-0 mr-md-1"
              data-dismiss="modal"
              @click="
                emailPolicy.email = false;
                emailPolicy.mobile = false;
              "
              data-selenium-name="btn-createPolicy-email-back"
            >
              {{ $t("cancel") }}
            </button>
            <button
              type="button"
              class="btn btn-confirm ml-0 ml-md-1 mr-0 mr-md-n1"
              :disabled="!isValidPolicyEmail"
              @click="emailPolicyAction().submitEmail(); eventTracking('Oppo','quotationResultPackages', 'Opportunity_save_Email', recognizeProduct(Global.ciLabel));"
              data-selenium-name="btn-createPolicy-email-next"
            >
              {{ $t("submit") }}
            </button>
          </div>

          <div
            class="modal-footer d-flex flex-column flex-md-row justify-content-center d px-2 px-md-3 pb-2"
            v-bind:key="'emailPolicy-mobile'"
            v-if="emailPolicy.email && !emailPolicy.mobile"
          >
            <button
              type="button"
              class="btn btn-cancel mb-2 mb-md-0 ml-0 ml-md-n1 mr-0 mr-md-1"
              @click="emailPolicy.email = false"
              data-selenium-name="btn-createPolicy-mobile-back"
            >
              {{ $t("cancel") }}
            </button>
            <button
              type="button"
              class="btn btn-confirm ml-0 ml-md-1 mr-0 mr-md-n1"
              :disabled="!isValidPolicyMobile"
              @click="emailPolicyAction().submitMobile(); eventTracking('Oppo','quotationResultPackages', 'Opportunity_save_Email_phone', recognizeProduct(Global.ciLabel));"
              data-selenium-name="btn-createPolicy-mobile-next"
            >
              {{ $t("submit") }}
            </button>
          </div>

          <div
            class="modal-footer d-flex flex-column flex-md-row justify-content-center d px-2 px-md-3 pb-2"
            v-bind:key="'emailPolicy-finished'"
            v-if="emailPolicy.email && emailPolicy.mobile"
          >
            <button
              type="button"
              class="btn btn-cancel mb-2 mb-md-0 ml-0 ml-md-n1 mr-0 mr-md-1 w-50"
              data-selenium-name="btn-createpolicy-customize"
              @click="emailPolicyAction().customize()"
            >
              {{ $t("eQuoteCustomize") }}
            </button>
            <button
              type="button"
              data-selenium-name="btn-createpolicy-buyNow"
              class="btn btn-confirm ml-0 ml-md-1 mr-0 mr-md-n1 w-50"
              :disabled="totalPremium < 1"
              @click="emailPolicyAction().confirm()"
            >
              {{ $t("confirmPackage") }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="row" style="margin:auto">
      <div class="floating-continue3x">
        <div class="floating-wrap">
          <div class="col-12 mb-2 order-sm-1">
            <button
              class="btn btn-buynow btn-block"
              data-selenium-name="floating-buynow"
              :disabled="totalPremium < 1"
              @click="buynow();
              eventLog('Buy_now', 'Fixed_bottom');"
            >
              {{ $t("confirmPackage") }}
            </button>
          </div>
          <!-- <div class="col-12 mb-2 order-sm-2">
            <span style="margin:auto; display:table; color:#fff;">
              {{ $t("eQuoteGet") }}
            </span>
          </div> -->
          
          <div class="col-12 col-sm-5 order-sm-2 order-last">
            <button class="btn btn-block btn-email"
              data-selenium-name="floating-eQuote"
              data-toggle="modal"
              data-target="#email-policy-modal"
              @click="eventLog('Save_Quote', 'Fixed_bottom'); eventTracking('Oppo','quotationResultPackages', 'Opportunity_email_quote', recognizeProduct(Global.ciLabel));"
            >
              <span class="glyphicons glyphicons-envelope" style="top:-1px"></span>

              {{ $t("eQuoteTitle") }}
              
              <br>
              <!-- &nbsp;{{ $t("eQuoteEmail") }} -->
            </button>
          </div>
          <!-- <div class="col-12 col-sm-4">
            <button class="btn btn btn-block btn-wa"
              data-selenium-name="floating-Whatsapp"
              data-toggle="modal"
              data-target="#roojai-wa-modal"
              @click="eventLog('whatsapp', 'Fixed_bottom');">
              {{ $t("eQuoteWhatsapp")}}
            </button>
          </div> -->
          <div class="col-12 col-sm-7 order-sm-3">
            <button class="btn btn-callMeback btn-block"
              data-selenium-name="floating-callMeback"
              data-toggle="modal"
              data-target="#roojai-call-back-modal"
              v-if="!Global.isAgentTraditionalBroker"
              @click="eventLog('call_me_back', 'Fixed_bottom');"> 
              <span class="glyphicons glyphicons-earphone"></span> {{ $t("eQuoteTitleCall") }}<br>
              <!-- &nbsp;{{ $t("eQuoteCall") }} -->
            </button>
          </div>
        </div>
      </div>
    </div>
    
    <div class="modal modal-v1 fade" id="roojai-wa-modal" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content white-bg">
          <div class="modal-header">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              data-selenium-name="wa-close"
            >
              X
            </button>
            <h5 class="modal-title mt-4 text-center h6">
              {{ $t("wacall") }}
            </h5>
          </div>
          <div class="modal-body">
            <input
              type="tel"
              class="form-control input-lg"
              maxlength="15"
              v-model.trim="Global.phaccount.phoneNumber"
              :placeholder="$t('yourMobilePhone')"
              data-selenium-name="callMeBack-mobile"
            />
            <div class="text-muted mt-2">
              <span class="glyphicons glyphicons-info-sign" style="color:#003b71"></span>
              &nbsp;<span>{{ $t("pleaseEnterYourWaNumber") }}</span>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-primary w-100"
              :disabled="!isValidPolicyMobile"
              @click="waMeBack(); eventTracking('Oppo','quotationResultPackages', 'Opportunity_wa_me', recognizeProduct(Global.ciLabel));"
              data-selenium-name="callmeback-submit"
            >
              {{ $t("wacall") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal modal-v1 fade" id="thankyou-modal-call" tabindex="-1">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                X
              </button>
              <div class="text-center">
                <img
                  src="images/rj-thank-you.png"
                  data-src="images/rj-thank-you.png"
                  style="border-radius: 50%;border: 1px solid #eee;"
                  class="lazy"
                />
              </div>
            </div>
            <div class="modal-body">
              <div
                class="text-default text-center pb-3"
                v-if="!Global.isAgentCustomerView"
              >
              {{ $t("thankyoucall") }} <br>
              {{ $t("thankyouweekday") }} <br>
              {{ $t("thankyouweekend") }}
              </div>
              <div
                class="text-default text-center pb-3"
                v-if="Global.isAgentCustomerView"
              >
                {{ $t("thankyoucallAgent") }}
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import Mixin from "@/components/Mixin";
import Global from "@/store/global.js";
import axios from "axios";
import accounting from "accounting-js";

export default {
  mixins: [Mixin],
  name: "Package",
  data() {
    return {
      Global,
      packageList: {},
      emailPolicy: {
        email: false,
        mobile: false
      },
      packageSummary:{
        Cancer:{
          text:"cancer.title",
          coverages:{
            "Skin cancer - Melanoma": {
              text:"cancer.coverage",
              textUpto:"",
              value:""
            }
          }
        },
        Neuro:{
          text:"neuro.title",
          coverages:{
            "Neuro": {
              text:"neuro.coverage",
              textUpto:"",
              value:""
            }
          }
        },
        Cardiovascular:{
          text:"cardiovascular.title",
          coverages:{
            "Cardiovascular": {
              text:"cardiovascular.coverage",
              textUpto:"",
              value:""
            }
          }
        },
        "OrganFailure":{
          text:"organfailure.title",
          coverages:{
            "OrganFailure": {
              text:"organfailure.coverage",
              textUpto:"",
              value:""
            }
          }
        },
        Dengue:{
          text:"dengue.title",
          coverages:{
            "Medical Expenses": {
              text:"dengue.coverage",
              textUpto:"",
              value:""
            }
          }
        },
        // Covid19:{
        //   text:"covid.title",
        //   coverages:{
        //     Coma:{
        //       text:"covid.coverages.coma",
        //       textUpto:"cover",
        //       value:""
        //     },
        //     "Medical Expenses - Inpatient":{
        //       text:"covid.coverages.medicalExpensesInpatient",
        //       textUpto:"coverUpto",
        //       value:""
        //     },
        //     "Medical Expenses - Outpatient":{
        //       text:"covid.coverages.medicalExpensesOutpatient",
        //       textUpto:"coverUpto",
        //       value:""
        //     },
        //     "PA - Covid":{
        //       text:"covid.coverages.pa",
        //       textUpto:"coverUpto",
        //       value:""
        //     }
        //   }
        // }
      },
      backgroundImg:{
        Cancer: "bg-cancer.png",
        Neuro: "bg-brain.png",
        Cardio: "bg-heart.png",
        Organ: "bg-lung.png",
        Mosquito: "bg-mosquito.png",
        Covid19: "bg-covid.png",
        UnrelatedDeath: "bg-other.png"
      },
      productSplice: null,
      productFilter: null
    }
  },
  async created() {
    if (Global.radarCallType !== "1") {
      this.loading("show");
      Global.radarCallType = "1";
      try {
        var planResponse = await this.getPlans();
        Global.packages = planResponse.data.quote;

        Global.quote.mastersetId = planResponse.data.masterSetId;
        Global.isSuperCI = planResponse?.data?.quote?.superCI?.value != undefined ? planResponse.data.quote.superCI.value : Global.isSuperCI;

        this.setSelectedPackages();

        const businessRulesResult = planResponse.data.quote.businessRulesResult.value
          .split(",")
          .filter(rule => rule !== "0");

        this.loading("hide");
        if (businessRulesResult.length > 0) {
          this.errorModal(this.$t("packageUnavailable"), 'title', this.$t("packageUnavailableTitle"), this.$t("packageUnavailableContact"), this.$t("packageUnavailable2"));
        }
        Global.isBack = false;
      } catch (error) {
        console.error(error);
      }
    }
  },
  mounted() {
    var vm = this;
    this.loading("show", function() {
      setTimeout(function() {
        vm.loading("hide");
      }, 500);
    });
    $(document).ready(function(){
      $('[data-toggle="tooltip"]').tooltip();   
    });

    if (!this.isAllowInstallment) {
      Global.paymentFrequency = "Yearly"
    }

    window.scrollTo(0, 0);
    this.bindScroll();

    this.mobileBack();
    this.itemScroll();
    if (vm.productSplice == null && vm.productFilter == null) {
      vm.setProductList();
    }
  },
  methods: {
    setProductList: function() {
      this.productSplice = Global.productList;
      this.productFilter = Global.productList;
      if (Global.defaultPreSelected != "") {
        if (Global.defaultPreSelected == "Cancer") {
            this.productFilter = this.productFilter.filter((product) =>{
              return product.name.value == Global.defaultPreSelected
            })
        }
        else if(Global.defaultPreSelected == "Dengue" || Global.defaultPreSelected == "Cardiovascular"){
          this.productSlice = this.productSplice.slice(0,1)
          this.productFilter = this.productFilter.splice(0,1)
        }
      }else{
        this.productFilter = Global.productList
      }
    },
    premiumShow: function(priceNext, priceCurrent, isInstalment){
      // priceNext = parseInt(priceNext.replace(".",""))
      if(priceCurrent == null){
        priceCurrent = 0;
      }
      if(isInstalment){
        priceCurrent = parseInt((priceCurrent * Global?.packages?.twelveMonthsNoDepositPaymentLoadingFactor?.value / 12).toFixed(0));
      }
      // (this.getTwelveMonthsNoDepositPremium(_product, _option) / 12)
      // var currentPremium = isInstalment ? this.twelveMonthsNoDepositPremium.monthlyInstallment : this.totalPremium
      // , comparePremium = isInstalment ? this.calculatePremiumShowMonthly(compareOption) : this.calculatePremiumShowYearly(compareOption)
      // , premiumShow = {
      //     "premium": Math.abs(currentPremium - comparePremium),
      //     "isMinus": Number(currentPremium) > Number(comparePremium)
      //   }
      var premiumShow = {
          "premium": Math.abs(priceCurrent - priceNext),
          "isMinus": Number(priceCurrent) > Number(priceNext)
      }
      return premiumShow;
    },
    scroll_left(product) {
      let content = document.querySelector("#custom-scroll-"+product);
      let scrollAmount = 0;
      let slideTimer = setInterval(function(){
          content.scrollLeft -= 10;
          scrollAmount += 10;
          if(scrollAmount >= 100){
              window.clearInterval(slideTimer);
          }
      }, 25);
      // content.scrollLeft -= 50;
    },
    scroll_right(product) {
      let content = document.querySelector("#custom-scroll-"+product);
      let scrollAmount = 0;
      let slideTimer = setInterval(function(){
        content.scrollLeft += 10;
        scrollAmount += 10;
        if(scrollAmount >= 100){
            window.clearInterval(slideTimer);
        }
      }, 25);
    },
    bindScroll:function(){
      $(window).off("scroll");
      $(window).on("scroll", function() {
        if ($(window).scrollTop() > 240) {
          $("#ci-packages-header").addClass("fixed-menu");
          // $("nav").addClass("fixed-menu");
          $("body").addClass("scroll-down");
        } else {
          $("#ci-packages-header").removeClass("fixed-menu");
          // $("nav").removeClass("fixed-menu");
          $("body").removeClass("scroll-down");
        }

        var packageSummary = $("#buy-now").offset().top - 50;
        var scrollBottom = $(window).height() + $(window).scrollTop();

        if( scrollBottom < packageSummary ){
          $(".floating-continue3x").show();
        }else{
          $(".floating-continue3x").hide();
        }
      });
    },
    buynow: async function() {
      if( this.totalPremium < 1 ){
        return false;
      }

      this.loading("show");
      Global.quote.onlineStage = "Proceed to Policy Details 1";
      // Global.quote.callMeBack = false;
      // var options = {
      //   callMeBackRequest : 'No'
      // }
      // this.saveQuote(options);
      this.saveQuote();

      var category = "&c=" + Global.productSorting;
      // var category = "";
      // for (const [key, value] of Object.entries(Global.selectedPackages)) {
      //   if (
      //     Global.enableCoverList?.includes(key) &&
      //     value?.premiumBeforeTaxes?.value > 0
      //   ) {
      //     category += "&c=" + key;
      //   }
      // }

      try {
        Global.packages.healthQuestionSetCode.value = "2";
        const response = await axios.get(
          Global.roojaiService.url +
            "/product-questions?v=" +
            Global.packages.healthQuestionSetCode.value +
            category,
          {
            headers: { Authorization: "Bearer " + Global.roojaiService.token }
          }
        );

        Global.productQuestions = response?.data?.data;

        this.loading("hide");
        this.$router.push(Global.productConfig.route.policydetails1);
      } catch (error) {
        console.error(error);
      }
    },
    async waMeBack() {
      var vm = this;
      Global.quote.onlineStage = "WhatsApp me my quote";
      vm.saveQuote();
      var options = {
        callMeBackRequest : 'Yes'
      }
      await this.sendWhatsApp();
      if (Global.phaccount.sendWaStatus == 'success') {
        window.open('https://api.whatsapp.com/send?phone=622150890822&text=Ya saya mau terima penawaran sekarang', '_blank');
      }
      this.callMeBackRequest( Global.phaccount.phoneNumber, options);
      $("#roojai-wa-modal").modal("hide");
    },
    emailPolicyAction: function() {
      var vm = this;
      return {
        submitEmail: function() {
          vm.emailPolicy.email = true;
          Global.quote.sendEmailFlag = true;
          Global.quote.onlineStage = "Email me my quote";
          vm.saveQuote();
          Global.quote.sendEmailFlag = false;
          // $("#email-policy-modal").modal("hide");
        },
        submitMobile: function() {
          Global.quote.callMeBack = true;
          Global.quote.callMeBackRequest = 'Yes';
          Global.quote.onlineStage = "Call me back";

          // if (Global.phaccount.lastName === "") {
          //   Global.phaccount.lastName = Global.phaccount.phoneNumber;
          // }
          var options = {
            callMeBack: true,
            callMeBackRequest: 'Yes'
          }
          vm.saveQuote(options);

          // if (Global.phaccount.lastName === Global.phaccount.phoneNumber) {
          //   Global.phaccount.lastName = "";
          // }
          // Global.quote.callMeBack = false;
          vm.emailPolicy.mobile = true;
          //send quote
        },
        skipMobile: function() {
          vm.emailPolicy.mobile = true;
          Global.quote.callMeBackRequest = ""
          Global.quote.callMeBack = null
          vm.saveQuote();
        },
        customize: function() {
          $("#email-policy-modal").modal("hide");
          vm.emailPolicy.email = false;
          vm.emailPolicy.mobile = false;
        },
        confirm: function() {
          $("#email-policy-modal").modal("hide");
          vm.emailPolicy.email = false;
          vm.emailPolicy.mobile = false;
          vm.saveQuote();

          vm.buynow();
        }
      };
    },
    mobileBack: function() {
      var vm = this;
      var root_url = window.location.href.split("/#/")[0];

      $(".modal").off("shown.bs.modal");
      $(".modal").on("shown.bs.modal", function(e) {
        history.pushState("packages", "", root_url + "/#/packages");
      });

      $(".modal").off("hidden.bs.modal");
      $(".modal").on("hidden.bs.modal", function(e) {
        history.replaceState("packages", "", root_url + "/#/packages");

        if (this.id == "email-policy-modal") {
          vm.emailPolicy.email = false;
          vm.emailPolicy.mobile = false;
        }
      });

      $(window).off("popstate");
      $(window).on("popstate", function(event) {
        $(".modal").modal("hide");
      });
    },
    callMeBack: function() {
      var options = {
        callMeBack: true,
        callMeBackRequest: 'Yes'
      }
      $("#thankyou-modal-call").modal("show")
        setTimeout(function () {
            $("#thankyou-modal-call").modal("hide")
        }, 5000);
      Global.quote.onlineStage = "Call me back";
      Global.quote.callMeBack = true;
      Global.quote.callMeBackRequest = "Yes";
      this.saveQuote(options)
      // this.callMeBackRequest( Global.phaccount.phoneNumber, options);
      $("#roojai-call-back-modal").modal("hide");
    },
    getPackageLabel: function(coverName, index) {
      var vm = this;
      if("covid19".includes(coverName.toLowerCase())){
        var label = "";
          if (index == 0) label = vm.$t("packageLabel.saving");
          if (index == 1) label = vm.$t("packageLabel.caring");
          if (index == 2) label = vm.$t("packageLabel.valuable");
          if (index == 3) label = vm.$t("packageLabel.protect");
          return label;
      }else{
        var label = vm.$t("packageLabel.premium");
          if (index == 0) label = vm.$t("packageLabel.value");
          if (index == 1) label = vm.$t("packageLabel.standard");
          if (index == 2) label = vm.$t("packageLabel.recommended");
          if (index == 3) label = vm.$t("packageLabel.premium");
          return label;
      }
    },
    getCoverageAdditionalUpto(additionalCoverageName) {
      return additionalCoverageName.toLowerCase() != "coma"
        ? this.$t("packageLabel.upto")
        : "";
    },
    itemScroll:function(){
      $(".custom-scroll").each(function(){
          var scrollBoxWidth = $(this).width();
          var wrapperWidth = $(this).find(".item").length * 150;
          var activeIndex = $(this).find(".item.active").index()
          var scrollLeft = $(document).width() < 450 ? activeIndex == 1 ? 75 : 150 : activeIndex == 1 ? 70 : 120 ;

          if( wrapperWidth > scrollBoxWidth ){
            var activeIndex = $(this).find(".item.active").index();
            $(this).animate({scrollLeft: scrollLeft*activeIndex}, 500);
          }
      });
    },
    getProductPackage: function(productName){
       if (Global.packages?.coversList?.cover) {
         return  Global.packages.coversList.cover.find(p => p.name.value.toLowerCase() === productName.toLowerCase());
       }
    },
    getPriceByProductOption(_product, _option){
      if(Global.paymentFrequency === 'Yearly'){
        // let premiumStamp
        // if (_option.premiumBeforeTaxes.value > 5000000 ) {
        //   premiumStamp = _option.premiumBeforeTaxes.value + 10000
        // }else{
        //   premiumStamp = _option.premiumBeforeTaxes.value
        // }
        // var calculatePrice = this.calculatePrice(premiumStamp > 0 ? premiumStamp : 0);
        var calculatePrice = this.calculatePrice(_option?.premiumBeforeTaxes?.value > 0 ? _option.premiumBeforeTaxes.value : 0);
        return this.formatNumber(calculatePrice);
      }else{
        return this.formatNumber(this.getTwelveMonthsNoDepositPremium(_product, _option) / 12);
      }

    },
    getPriceByProductOptionPremi(_product, _option){
      if(Global.paymentFrequency === 'Yearly'){
        // let premiumStamp
        // if (_option.premiumBeforeTaxes.value > 5000000 ) {
        //   premiumStamp = _option.premiumBeforeTaxes.value + 10000
        // }else{
        //   premiumStamp = _option.premiumBeforeTaxes.value
        // }
        var calculatePrice = this.calculatePrice(_option?.premiumBeforeTaxes?.value > 0 ? _option.premiumBeforeTaxes.value : 0);
        // var calculatePrice = this.calculatePrice(premiumStamp > 0 ? premiumStamp : 0);
        return calculatePrice;
      }else{
        return this.getTwelveMonthsNoDepositPremium(_product, _option) / 12;
      }

    },
    closePaymentChangeModal:function(){
      $("#payment-change-to-yearly").modal("hide");
      Global.paymentFrequency = 'Yearly';
    },
    getTwelveMonthsNoDepositPremium: function(_product, _option) {
      var totalPremium = 0;
      if (_option?.premiumBeforeTaxes?.value > 0) {
        totalPremium = this.calculatePrice(
          _option.premiumBeforeTaxes.value *
            Global?.packages?.twelveMonthsNoDepositPaymentLoadingFactor?.value
        );
      }
      return totalPremium;
    },
    isDisableSwitchInstallment: function(_product){
      return !Global.selectedPackages[_product.name.value] || _product.name.value === 'Covid19'
    },
    selectNotCover:function(_product){
      if( this.isAllowSelectNotCovered && Global.selectedPackages[_product.name.value] ){
        delete Global.selectedPackages[_product.name.value];
      }
    },
    switchPaymentFrequency:function(frequency){
      if( !this.isAllowInstallment ){
        return false;
      }

      Global.paymentFrequency = frequency;
    },
    recognizeProduct: function(value) {
      if(value !== "CI") return "CI_" + value;
      else return "CI";
    }
  },
  watch: {
    "Global.phaccount.phoneNumber": function(value) {
      Global.phaccount.phoneNumber = value.replace(/[^0-9/]/g, "");
    }
  },
  computed: {
    isValidPolicyEmail: function() {
      return (Global.isAgentCustomerView && Global.brokerInfo.intermediaryReptEmail != '') || this.validate(Global.phaccount.email).isEmail();
    },
    isValidPolicyMobile: function() {
      return this.validate(Global.phaccount.phoneNumber).isMobileNumber();
    },
    isAllowSelectNotCovered: function() {
      return Global.productList.length > 1;
    },
    // filterProduct: function() {
    //   if(Global.defaultPreSelected != ""){
    //       return Global.productList.filter((product) =>{
    //         return product.name.value == Global.defaultPreSelected
    //       })
    //   }else{
    //     return Global.productList
    //   }
    // },
    // filterPreProduct: function() {
    //   if(Global.defaultPreSelected == "Dengue" || Global.defaultPreSelected == "Cardiovascular"){
    //     this.productSplice = Global.productList.splice(0,1)
    //     return this.productSplice
    //   }
    // },
    summaryPackages:function(){
      var firstProduct = {};
      var products = {};
      var cover = [];
      var uncover = [];
      var unproducts = {};
      var summary = this.packageSummary;
      var cancer = ['Cancer']

      if( !Global.selectedPackages ){
        return {};
      }
      if (Global.defaultPreSelected == "Cancer") {
        summary = Object.keys(summary).reduce((obj, key) => {
          if (cancer.includes(key)) {
          obj[key] = summary[key];
          }
          return obj;
        }, {});
      }
      for (const [key, value] of Object.entries(summary)) {
        if( Global.selectedPackages[key] ){
          var coverages = value.coverages;
          for( var i in coverages ){
            if( Global.selectedPackages[key]?.coverages?.coverage ){
              var found = Global.selectedPackages[key].coverages.coverage.find(function(each){
                return each.additionalCoverageName.value == i;
              });
              // console.log(i,"found", found);
              value.coverages[i].value = found?.additionalCoverageSumAssured?.value;
            }else{
              value.coverages[i].value = Global.selectedPackages[key]?.sumAssured?.value;
            }
          }

          if( Global.productSorting == key.toLowerCase() ){
            firstProduct[key] = value;
          }else{
            products[key] = value;
            cover.push(products[key])
          }
        }else{
          unproducts[key] = value;
          uncover.push(unproducts[key])
          // console.log("keyss", key);
        }
      }
      var covers = {
        "cover":cover,
        "uncover":uncover
      }
      // console.log(covers);
      
      // return Object.assign(firstProduct, products);
      return covers;
    },
    isInstallmentByProductFrequency: function(){
      var _isInstallmentByProductFrequency = Global.productList.find(p => p.paymentFrequency != 'Yearly') != null && this.twelveMonthsNoDepositPremium > 0;
      if(_isInstallmentByProductFrequency){
        Global.paymentFrequency = '12 months, no deposit';
      }else{
        Global.paymentFrequency = 'Yearly';
      }
      return _isInstallmentByProductFrequency;
    },
    showAllPackage: function(){
      return Global.isRetrieve ? true : Global.productConfig.showAllPackage;
    }
  },
  updated() {
    this.itemScroll();
    this.bindScroll();
  },
  unmounted() {
    //fixed browser back
    $(".modal").off("shown.bs.modal");
    $(".modal").off("hidden.bs.modal");
    $(window).off("popstate");
    $(window).off("scroll");
  }
};
</script>
<i18n>
{
  "en": {
    "customizeYourPlan": "Customize your plan",
    "yourPolicy": "Your critical illness insurance policy",
    "yourIllnessPackage": "Your insurance policy",
    "fixed5year": "5-year fixed premium",
    "title-critical" : "Critical Illness Insurance",
    "title-dengue" : "Mosquito-Borne Diseases Insurance",
    "title-cancer" : "Cancer Insurance",
    "title-cardiovascular" : "Heart Diseases Insurance",
    "title-otherPackage" : "Additional Critical Illness Protection",
    "text-otherPackage" : "Check out and add our other critical illness products below : ",
    "cancer": {
      "title": "Cancer",
      "titleShort": "Cancer",
      "tooltips": "You will receive 25% of the sum insured if the cancer is at an early stage, then the remaining 75% should the cancer develop to a late stage. Receive 100% of the lump sump if the cancer is detected at a late stage. There is a 90-day waiting period before you are eligible for benefits.",
      "coverage": "Invasive cancer, early stage and late stage",
      "skin": "Other skin cancers (except malignant melanoma)",
      "coverages":{
        "SkinCancer":"Other skin cancers (except malignant melanoma)",
        "SkinCancerMelanoma":"Any kind of cancer (including malignant melanoma)"
      }
    },
    "neuro": {
      "title": "Neurological Conditions",
      "titleShort": "Neuro",
      "tooltips": "You will receive 100% lump sum if you are diagnosed with one of the specified neurological disorders. There is a 90-day waiting period before you are eligible for benefits.",
      "coverage": "Coma, Cerebral Aneurysm, Alzheimer's Disease and Parkinson's Disease",
    },
    "cardiovascular": {
      "title": "Heart Disease",
      "titleShort": "Heart Disease",
      "tooltips": "You will receive 100% lump sum payment if you require surgery as diagnosed by a cardiologist. There is a 90-day waiting period before you are eligible for benefits.",
      "coverage": "Ischemic or Haemorrhagic Stroke, Acute Heart Attack and Coronary Artery Disease",
    },
    "organfailure": {
      "title": "Kidney Failure",
      "titleShort": "Kidney Failure",
      "tooltips": "You will receive 100% lump sum payment for conditions requiring regular dialysis or kidney transplantation. There is a 90-days waiting period until you are eligible for benefits. ",
      "coverage": "Chronic Kidney Failure",
    },
    "dengue": {
      "title": "Mosquito-borne Diseases",
      "titleShort": "Dengue​",
      "tooltips": "If you are diagnosed with any of the covered diseases, you will receive cashless treatments based on your medical bill at over 2000 Roojai hospitals , for a maximum of two hospitalizations per year. There is a 30-day waiting period before you are eligible for benefits.",
      "coverage": "Malaria, dengue, Chikungunya, Zika, and yellow fever",
    },
    "covid": {
      "title": "COVID-19",
      "titleShort": "COVID-19",
      "coverage":"Coma, severe brain injury, nervous system damage, terminal illness or death resulting from COVID-19",
      "coverageBold": "",
      "paCovid":"Compensation for loss of life, dismemberment, loss of sight or permanent disability (P.A. 1)",
      "coverages":{
        "coma":"If you suffer from coma, severe brain injury, nervous system damage, terminal illness caused by COVID-19",
        "medicalExpensesInpatient":"Medical expenses (in-patient) for COVID-19",
        "medicalExpensesOutpatient":"Medical expenses (out-patient) for COVID-19",
        "pa":"Additional Personal Accident coverage not related to COVID-19 for permanent disability, loss of organs, loss of sight and death"
      }
    },
    "packageLabel": {
      "notcover": "Not covered",
      "valuable": "Valuable",
      "value": "Value",
      "recommended": "Recommended",
      "standard": "Standard",
      "premium": "Premium",
      "premium+": "Premium+ Plan",
      "super": "Super Plan",
      "saving": "Saving Plan",
      "caring": "Caring Plan",
      "reimbursement" : "Claim reimbursement",
      "cashless" : "Claim Cashless",
      "protect": "Protect Plan",
      "upto": "up to"
    },
    "upto":"up to",
    "coverUpto":"up to",
    "cover":"",
    "month":"Month",
    "months":"months",
    "monthly":"Monthly",
    "annual":"Annual",
    "currency" :"IDR ",
    "yearly":"/annual",
    "firstInstallment":"First month",
    "seeMoreProducts":"Would you like to add {0} insurance?",
    "seeMoreProductsYes":"Yes",
    "seeMoreProductsNo":"No",
    "minimumWaitingPeriodNotes":"Please remember to send your proof of income within 14 working days after your policy has been issued.",
    
  },
  "id": {
    "customizeYourPlan": "Atur paketmu",
    "yourPolicy": "Polis asuransi penyakit kritis kamu",
    "yourIllnessPackage": "Detail pertanggungan",
    "fixed5year": "Premi tetap 5 tahun",
    "title-critical" : "Asuransi Penyakit Kritis",
    "title-dengue" : "Asuransi Penyakit Akibat Nyamuk",
    "title-cancer" : "Asuransi Penyakit Kanker",
    "title-cardiovascular" : "Asuransi Penyakit Jantung",
    "title-otherPackage" : "Proteksi tambahan",
    "text-otherPackage" : "Cek dan tambahkan proteksi dari penyakit kritis lainnya seperti : ",
    "cancer": {
      "title": "Penyakit Kanker",
      "titleShort": "Kanker",
      "tooltips": "Dapatkan santunan tunai sebesar 25% dari uang pertanggungan jika didiagnosa stadium awal, dan sisa uang pertanggungan bila didiagnosa stadium lanjut. Kamu juga bisa mendapatkan uang santunan sekaligus 100% jika kamu didiagnosa kanker stadium lanjut. Kamu berhak untuk mengajukan klaim setelah melalui masa tunggu selama 90 hari",
      "coverage": "Kanker invasif, stadium awal dan stadium lanjut",
      "skin": "Kanker kulit lainnya (kecuali melanoma maligna)",
      "coverages":{
        "SkinCancer":"Kanker kulit lainnya (kecuali melanoma maligna)",
        "SkinCancerMelanoma":"Segala jenis kanker (termasuk melanoma maligna)"
      }
    },
    "neuro": {
      "title": "Penyakit Saraf​",
      "titleShort": "Penyakit Saraf​",
      "tooltips": "Kamu akan menerima 100% santunan tunai jika didiagnosa salah satu penyakit gangguan saraf tersebut. Kamu berhak untuk mengajukan klaim setelah melalui masa tunggu selama 90 hari.",
      "coverage": "Koma, Aneurisma Cerebral, Penyakit Alzheimer dan Penyakit Parkinson​",
    },
    "cardiovascular": {
      "title": "Penyakit Jantung​​",
      "titleShort": "Penyakit Jantung​​",
      "tooltips": "Kamu akan mendapatkan 100% santunan tunai jika dokter spesialis jantung menyatakan kamu membutuhkan pembedahan. Kamu berhak untuk mengajukan klaim setelah melalui masa tunggu selama 90 hari.",
      "coverage": "Stroke Iskemik atau Hemoragik, Serangan Jantung Akut, dan Penyakit Arteri Koroner",
    },
    "organfailure": {
      "title": "Penyakit Gagal Ginjal",
      "titleShort": "Penyakit Gagal Ginjal​",
      "tooltips": "Kamu akan menerima 100% santunan tunai jika kamu memiliki kondisi yang mengaruskan cuci darah secara rutin atau transplantasi ginjal. Kamu berhak untuk mengajukan klaim setelah melalui masa tunggu 90 hari.",
      "coverage": "Gagal Ginjal Kronis",
    },
    "dengue": {
      "title": "Penyakit akibat Nyamuk​​",
      "titleShort": "Penyakit disebabkan oleh gigitan Nyamuk​​",
      "tooltips": "Dapatkan biaya pengobatan dengan sistem cashless, maksimum dua kali per tahun di 2000 rumah sakit rekanan Roojai. Kamu berhak untuk mengajukan klaim setelah melalui masa tunggu 30 hari.",
      "coverage": "Malaria, Demam Berdarah, Chikungunya, Zika, Demam Kuning​",
    },
    "covid": {
      "title": "COVID-19",
      "titleShort": "COVID-19",
      "coverage":"Koma, cedera otak parah, kerusakan sistem saraf, penyakit terminal, atau kematian akibat COVID-19",
      "coverageBold": "",
      "paCovid":"Santunan kematian, kehilangan anggota tubuh, kehilangan penglihatan atau cacat permanen (P.A. 1)",
      "coverages":{
        "coma":"Jika Kamu menderita koma, cedera otak parah, kerusakan sistem saraf, penyakit terminal yang disebabkan oleh COVID-19",
        "medicalExpensesInpatient":"Biaya pengobatan (rawat inap) untuk COVID-19",
        "medicalExpensesOutpatient":"Biaya pengobatan (rawat jalan) untuk COVID-19",
        "pa":"Pertanggungan tambahan kecelakaan diri yang tidak terkait dengan COVID-19 untuk cacat tetap, kehilangan organ tubuh, kehilangan penglihatan dan kematian"
      }
    },
    "packageLabel": {
      "notcover": "Tidak ditanggung",
      "valuable": "Ekonomis",
      "value": "Ekonomis",
      "recommended": "Rekomendasi",
      "saving": "Paket Hemat",
      "standard": "Standar",
      "premium": "Premium",
      "premium+": "Paket Premium+",
      "super": "Paket Super",
      "reimbursement" : "Klaim reimbursement",
      "cashless" : "Klaim Cashless",
      "caring": "Paket Perawatan",
      "protect": "Paket Proteksi",
      "upto": "sampai dengan"
    },
    "minimumWaitingPeriodNotes":"Mohon mengirimkan bukti pendapatan dalam 14 hari kerja setelah polis dikeluarkan.",
    "upto":"sampai dengan",
    "currency" :"Rp",
    "coverUpto":"sampai dengan",
    "cover":"",
    "month":"Bulan",
    "months":"Bulan",
    "monthly":"Per bulan",
    "annual":"Tahun",
    "yearly":"/tahun",
    "firstInstallment":"Bulan pertama",
    "seeMoreProducts":"Apakah kamu mau menambahkan asuransi {0} ?",
    "seeMoreProductsYes":"Ya",
    "seeMoreProductsNo":"Tidak"
  },
}
</i18n>
